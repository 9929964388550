import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  display: flex;

  div.store {
    border: 1px solid #d7e2ed;
    box-sizing: border-box;
    border-radius: 4px;

    width: 324px;
    height: 88px;
  }

  > div:last-child {
    min-width: 924px;
    padding-left: 21px;
    overflow: hidden;
  }
`;

export const CalendarContainer = styled.div`
  margin-top: 38px;

  h2 {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 22px;
    color: ${colors.dark};
  }

  p {
    font-family: "Source Sans Pro";
    font-size: 14px;
    color: ${colors.gray.text};
  }
`;

export const GridContainer = styled.div`
  .gridLoadingContainer {
    > div {
      height: auto;
    }
  }

  .gridHeader {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 50px);
    margin-top: 15px;

    h2 {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      font-size: 22px;
      color: ${colors.dark};
    }

    p {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.gray.text};
    }
  }

  .gridSubtitle {
    display: flex;
    align-items: center;
    
    color: ${colors.gray.text};
    font-family: "Source Sans Pro", sans-serif;

    margin-top: -25px;

    strong {
      font-size: 12px;
    }

    span {
      display: flex;
      align-items: center;
      
      margin-left: 4px;
      
      p {
        font-size: 10px;
        margin: 0;
      }

      img {
        width: 12px;
        height: 12px;

        margin-left: 2px;
      }
    }
  }
`;

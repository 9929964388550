import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button as ButtonPrime } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Card } from "primereact/card";
import { parseISO, isAfter } from "date-fns";
import * as Yup from "yup";
import axios from "axios";
import { Form } from "@unform/web";

import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import { Input } from "components/Input";
import Button from "components/Button";
import { InputCalendar } from "components/InputCalendar";
import SelectDropdown from "components/SelectDropdown";
import { SimpleModal } from "components/ModalGroup/SimpleModal";

import * as S from "./styles";
import { convert } from "utils/dateConverter";

import warningImg from "images/arrowWarning.png";

interface DropdownSelectedItem {
  value: number;
  label: string;
}

interface TipoParceriaData {
  id: number;
  nome: string;
}

interface KeyValuePair {
  index?: number;
  Key: string;
  Value: string;
}

export function EditarParceria() {
  const history = useHistory();
  const { state }: { state: any } = useLocation();

  const [isDisableInicio, setIsDisableInicio] = useState(false);
  const [isDisableTermino, setIsDisableTermino] = useState(false);
  const [nome, setNome] = useState(state.data.nome || "");
  const [inicio, setInicio] = useState(
    parseISO(
      convert({ dateString: state.data.vigenciaInicio, pattern: "yyyy-mm-dd" })
    )
  );
  const [termino, setTermino] = useState(
    parseISO(
      convert({ dateString: state.data.vigenciaFim, pattern: "yyyy-mm-dd" })
    )
  );
  const [tipoBeneficio, setTipoBeneficio] = useState(
    state.data?.tipoParceria.id || 0
  );
  const [tipoCadastro, setTipoCadastro] = useState(state.data?.tipo || 0);
  const [urlConsulta, setUrlConsulta] = useState(
    state.data.urlBaseExterna || ""
  );
  const [tiposParceria, setTiposParceria] = useState<TipoParceriaData[]>([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [headers, setHeaders] = useState<KeyValuePair[]>(
    state.data.headers || []
  );
  const [utilizarMascara, setUtilizarMascara] = useState(
    state.data.buscaComMascara ?? false
  );
  const [landingPageCadastro, setLandingPageCadastro] = React.useState(
    state.data.landingPageCadastro ?? false
  );
  const [counterHeader, setCounterHeader] = React.useState(0);
  const [isModalShow, setIsModalShow] = React.useState(false);
  const [seletedIndex, setSeletedIndex] = React.useState(-1);
  const [isLoadingInner, setIsLoadingInner] = useState(false);

  function handleGoBack() {
    history.push("/portaladmin/parcerias/detalhes?id=" + state.data.id, {
      data: state.data,
    });
  }

  const preventEnterEvent = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      return false;
    }
  };

  const adjustHeader = () => {
    headers.map((item) => {
      item.index = headers.findIndex((x) => x == item);
      setCounterHeader((prevent) => ++prevent);
    });

    setHeaders(headers);
  };

  useEffect(() => {
    adjustHeader();
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", preventEnterEvent);

    (async () => {
      const responseTipos = await axios.get("/beneficioData/GetAllTipo");

      if (responseTipos.data.result === "Success") {
        setTiposParceria(responseTipos.data.items);
      }
    })();

    return () => {
      window.removeEventListener("keydown", preventEnterEvent);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (tipoCadastro === 1) {
        setUrlConsulta("");
        if (headers?.length >= 1) setHeaders([]);
      }

      await validateForm({
        nome,
        inicio,
        termino,
        tipoBeneficio,
        tipoCadastro,
        urlConsulta,
        headers,
        utilizarMascara,
        landingPageCadastro,
      });
    })();
  }, [
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
  ]);

  async function validateForm({
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
  }: any) {
    setEnableSaveButton(false);

    if (isAfter(inicio, termino)) return;

    const schema = Yup.object().shape({
      nome: Yup.string().required(),
      vigenciaInicio: Yup.string().required(),
      vigenciaFim: Yup.string().required(),
      tipoBeneficio: Yup.number().required(),
      tipoCadastro: Yup.number().required(),
      urlBaseExterna: Yup.string().when("tipoCadastro", {
        is: (tipoCadastro: number) => tipoCadastro === 2,
        then: Yup.string().required(),
      }),
      utilizarMascara: Yup.bool().required(),
      landingPageCadastro: Yup.bool().required(),
      headers: Yup.array().of(
        Yup.object().shape({
          Key: Yup.string().required(),
          Value: Yup.string().required(),
        })
      ),
    });

    await schema.validate(
      {
        nome,
        vigenciaInicio: inicio,
        vigenciaFim: termino,
        tipoBeneficio,
        tipoCadastro,
        urlBaseExterna: urlConsulta,
        headers,
        utilizarMascara,
        landingPageCadastro,
      },
      {
        abortEarly: false,
      }
    );

    setEnableSaveButton(true);
  }

  async function handleUpdatePartnership() {
    const hash = localStorage.getItem("@portalAdmin:hash");
    let auxHeaders;
    if (headers) {
      auxHeaders = [...headers];
      auxHeaders = auxHeaders.map((item) => {
        delete item.index;
        return item;
      });
    }

    const updatedPartnership = {
      nome,
      vigenciaInicio: inicio,
      vigenciaFim: termino,
      tipo: tipoCadastro,
      idTipoParceria: Number(tipoBeneficio),
      urlBaseExterna: urlConsulta,
      beneficios: state.data.servicosVinculados,
      ativo: state.data.status === "active" ? true : false,
      headers: auxHeaders,
      buscaComMascara: utilizarMascara,
      landingPageCadastro,
    };

    await axios
      .put(
        `/beneficioData/UpdateParceria/${state.data.id}`,
        updatedPartnership,
        {
          headers: {
            pm_hash: hash,
          },
        }
      )
      .then((_) => {
        history.push("/portaladmin/parcerias/detalhes?id=" + state.data.id, {
          data: {
            id: state.data.id,
          },
        });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoadingInner(false);
        setIsModalShow(false);
      });
  }

  const removeHeader = (index: number) => {
    setHeaders((prevIndexes) => [
      ...prevIndexes.filter((item) => item.index !== index),
    ]);
    setCounterHeader((prevCounterHeader) => prevCounterHeader - 1);
  };

  const changeText = (index: number, chave: boolean, text: string) => {
    let newArr = [...headers];

    if (chave) newArr[index].Key = text;
    else newArr[index].Value = text;
    setHeaders(newArr);
  };

  return (
    <>
      <Header />
      <Main>
        <S.DashboardContainer>
          <DashboardUser />
        </S.DashboardContainer>
        <S.Content>
          <h2>EDIÇÃO DE PARCERIA</h2>
          <Card className="contentBody">
            <div className="form">
              <Form onSubmit={() => setIsModalShow(true)}>
                <div className="p-fluid p-formgrid p-grid">
                  <div id="criadoEm" className="p-field p-col-2">
                    <label htmlFor="criadoEm">Criado em:</label>
                    <Input
                      name="criadoEm"
                      value={state.data.criadoPor?.data}
                      readOnly
                      disabled
                    />
                  </div>
                  <div id="criadoPor" className="p-field p-col-7">
                    <label htmlFor="criadoPor">Criado por:</label>
                    <Input
                      name="criadoPor"
                      value={state.data.criadoPor?.colaborador}
                      readOnly
                      disabled
                    />
                  </div>
                  <div id="matricula" className="p-field p-col-3">
                    <label htmlFor="matricula">Matrícula:</label>
                    <Input
                      name="matricula"
                      value={state.data.criadoPor?.codigoColaborador}
                      readOnly
                      disabled
                    />
                  </div>
                  <div id="nome" className="p-field p-col-6 required">
                    <label htmlFor="nome">Nome</label>
                    <Input
                      name="nome"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="Nome completo da parceria"
                    />
                  </div>
                  <div id="tipoBeneficio" className="p-field p-col-2 required">
                    <label htmlFor="matipoBeneficiotricula">Tipo</label>
                    <SelectDropdown
                      name="tipoBeneficio"
                      placeholder="Selecione"
                      newValue={{
                        value: tipoBeneficio,
                        label:
                          tiposParceria.find(
                            (tipo) => tipo.id === tipoBeneficio
                          )?.nome || "Selecione",
                      }}
                      options={tiposParceria?.map((tipo) => ({
                        value: tipo.id,
                        label: tipo.nome,
                      }))}
                      onChange={(event: any) => setTipoBeneficio(event.value)}
                    />
                  </div>
                  <div id="tipoCadastro" className="p-field p-col-4 required">
                    <label htmlFor="tipoCadastro">Tipo de cadastro</label>
                    <SelectDropdown
                      name="tipoCadastro"
                      placeholder="Selecione"
                      newValue={{
                        value: tipoCadastro,
                        label:
                          [
                            {
                              value: 1,
                              label: "Manual",
                            },
                            {
                              value: 2,
                              label: "Automático",
                            },
                          ].find((tipo) => tipo.value === tipoCadastro)
                            ?.label || "Selecione",
                      }}
                      options={[
                        {
                          value: 1,
                          label: "Manual",
                        },
                        {
                          value: 2,
                          label: "Automático",
                        },
                      ]}
                      onChange={(event: any) => setTipoCadastro(event.value)}
                    />
                  </div>
                  <div id="inicio" className="p-field p-col-3 required">
                    <label htmlFor="inicio">Início</label>
                    <InputCalendar
                      name="inicioInput"
                      isRange={false}
                      defaultDateSelected={new Date(inicio)}
                      SetDateValidation={(valid) => setIsDisableInicio(valid)}
                      handleDateChange={(event) => setInicio(event as Date)}
                    />
                  </div>
                  <div id="termino" className="p-field p-col-3 required">
                    <label htmlFor="termino">Término</label>
                    <InputCalendar
                      name="termino"
                      isRange={false}
                      defaultDateSelected={new Date(termino)}
                      SetDateValidation={(valid) => setIsDisableTermino(valid)}
                      handleDateChange={(event) => setTermino(event as Date)}
                    />
                  </div>
                  <div
                    id="utilizarMascara"
                    className="p-field p-col-3 required"
                  >
                    <label htmlFor="utilizarMascara">Utilizar Máscara?</label>
                    <div className="p-formgroup-inline">
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="utilizarMascaraSim"
                          name="utilizarMascara"
                          value={utilizarMascara}
                          onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                          checked={utilizarMascara}
                          disabled={tipoCadastro !== 2}
                        />
                        <label htmlFor="utilizarMascaraSim">Sim</label>
                      </div>
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="utilizarMascaraNao"
                          name="utilizarMascara"
                          value={utilizarMascara}
                          onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                          checked={!utilizarMascara}
                          disabled={tipoCadastro !== 2}
                        />
                        <label htmlFor="utilizarMascaraNao">Nao</label>
                      </div>
                    </div>
                  </div>
                  <div
                    id="landingPageCadastro"
                    className="p-field p-col-3 required"
                  >
                    <label htmlFor="landingPageCadastro">
                      Utilizar landing page de cadastro de clientes?
                    </label>
                    <div className="p-formgroup-inline">
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="landingPageCadastroSim"
                          name="landingPageCadastro"
                          value={landingPageCadastro}
                          onChange={(e) =>
                            setLandingPageCadastro(!landingPageCadastro)
                          }
                          checked={landingPageCadastro}
                        />
                        <label htmlFor="landingPageCadastroSim">Sim</label>
                      </div>
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="landingPageCadastroNao"
                          name="landingPageCadastro"
                          value={landingPageCadastro}
                          onChange={(e) =>
                            setLandingPageCadastro(!landingPageCadastro)
                          }
                          checked={!landingPageCadastro}
                        />
                        <label htmlFor="landingPageCadastroNao">Não</label>
                      </div>
                    </div>
                  </div>
                  <div id="urlConsulta" className="p-field p-col-8 required">
                    <label htmlFor="urlConsulta">
                      Insira a url de consulta
                    </label>
                    <Input
                      name="urlConsulta"
                      value={urlConsulta}
                      onChange={(event) => setUrlConsulta(event.target.value)}
                      placeholder="Insira a url de consulta"
                      disabled={tipoCadastro !== 2}
                    />
                  </div>
                  <div
                    className={classNames({
                      "p-field p-col-12 p-m-0": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                    style={{ opacity: 0 }}
                  ></div>
                  {headers?.map((header: KeyValuePair) => {
                    if (header)
                      return (
                        <React.Fragment
                          key={header.index || header.Key + "_" + header.Value}
                        >
                          <div
                            id="headers"
                            className={classNames({
                              "p-field p-col-4 required": true,
                              "p-d-none": tipoCadastro !== 2,
                            })}
                          >
                            <label htmlFor={`chave${header.Key}`}>Chave</label>
                            <InputText
                              name={`chave${header.Key}`}
                              value={header.Key}
                              onChange={(e) =>
                                changeText(
                                  header.index ?? -1,
                                  true,
                                  e.target.value
                                )
                              }
                              placeholder="Insira a chave da requisição"
                              disabled={tipoCadastro !== 2}
                            />
                          </div>
                          <div
                            className={classNames({
                              "p-field p-col required": true,
                              "p-d-none": tipoCadastro !== 2,
                            })}
                          >
                            <label htmlFor={`valor${header.Value}`}>
                              Valor
                            </label>
                            <div className="p-formgroup-inline">
                              <div className="p-field flex-1 p-mb-0">
                                <InputText
                                  name={`valor${header.Value}`}
                                  value={header.Value}
                                  onChange={(e) =>
                                    changeText(
                                      header.index ?? -1,
                                      false,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Insira o valor da chave da requisição"
                                  disabled={tipoCadastro !== 2}
                                />
                              </div>
                              <ButtonPrime
                                type="button"
                                icon="pi pi-trash"
                                className="custom-btn-remove p-button-danger"
                                onClick={() => {
                                  setSeletedIndex(header.index ?? -1);
                                  setIsModalShow(true);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames({
                              "p-field p-col-12 p-m-0": true,
                              "p-d-none": tipoCadastro !== 2,
                            })}
                            style={{ opacity: 0 }}
                          ></div>
                        </React.Fragment>
                      );
                  })}
                  <div
                    className={classNames({
                      "p-field p-col-12 p-m-0": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                    style={{ opacity: 0 }}
                  ></div>
                  <div
                    className={classNames({
                      "p-field p-col-3": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                  >
                    <ButtonPrime
                      type="button"
                      label="Adicionar Header"
                      icon="pi pi-check"
                      disabled={headers.length >= 3}
                      className="backgroud-color-default"
                      onClick={(_) => {
                        _.preventDefault();
                        setHeaders((values) => [
                          ...values,
                          { index: counterHeader, Key: "", Value: "" },
                        ]);
                        setCounterHeader(
                          (prevCounterHeader) => prevCounterHeader + 1
                        );
                      }}
                    />
                  </div>
                  {state.data.ultimaAlteracao &&
                    state.data.ultimaAlteracao.data && (
                      <>
                        <div
                          className={classNames({
                            "p-field p-col-12 p-m-0": true,
                            "p-d-none": tipoCadastro !== 2,
                          })}
                          style={{ opacity: 0 }}
                        ></div>
                        <div id="editadoEm" className="p-field p-col-2">
                          <label htmlFor="editadoEm">Editado em:</label>
                          <Input
                            name="editadoEm"
                            value={state.data.ultimaAlteracao.data}
                            readOnly
                            disabled
                          />
                        </div>
                        <div id="editadoPor" className="p-field p-col-7">
                          <label htmlFor="editadoPor">Editado por:</label>
                          <Input
                            name="editadoPor"
                            value={state.data.ultimaAlteracao.colaborador}
                            readOnly
                            disabled
                          />
                        </div>
                        <div id="matriculaEditor" className="p-field p-col-3">
                          <label htmlFor="matriculaEditor">Matrícula:</label>
                          <Input
                            name="matriculaEditor"
                            value={state.data.ultimaAlteracao.codigoColaborador}
                            readOnly
                            disabled
                          />
                        </div>
                      </>
                    )}
                </div>
                <div className="contentFooter p-d-flex p-jc-end p-mt-5">
                  <Button inverted onClick={handleGoBack}>
                    Cancelar
                  </Button>
                  <Button
                    disabled={
                      isDisableInicio || isDisableTermino || !enableSaveButton
                    }
                    type="submit"
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </S.Content>
      </Main>
      <Footer />

      <SimpleModal
        isOpen={isModalShow}
        onRequestClose={() => {
          if (seletedIndex > -1) setIsModalShow(false);
        }}
        confirm={() => {
          setIsLoadingInner(true);
          if (seletedIndex > -1) {
            removeHeader(seletedIndex);
            setIsModalShow(false);
            setSeletedIndex(-1);
            setIsLoadingInner(false);
          } else handleUpdatePartnership();
        }}
        loading={isLoadingInner}
        icon={warningImg}
        title="Atenção"
        message={
          seletedIndex > -1
            ? "Deseja remover esta configuração?"
            : "Deseja salvar essas informações?"
        }
        showCancel
      />
    </>
  );
}

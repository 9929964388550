import axios from 'axios'

export const authorizationService = {

    acquireToken: async () => {
        const req = await axios.get('/authorizer/AcquireAccessToken')
        const { data } = req
        return data
    }
}

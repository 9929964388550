import {
  createContext,
  MouseEvent,
  ReactNode,
  useContext,
  useState,
} from "react";
import axios from "axios";

interface AuthContextData {
  username: string;
  setUsername: (name: string) => void;
  matricula: string;
  cargo: string;
  nivelAcesso: string;
  permissoes: PermissoesProps[];
  setPermissoes: (permissoes: PermissoesProps[]) => void;
  isLoading: boolean;
  handleSession: () => void;
  handleSignIn: (matricula: string, atualizarHash: boolean) => Promise<void>;
  handleSignOut: (event: MouseEvent<HTMLAnchorElement>) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface PermissoesProps {
  route: string[];
  label: string;
}

interface UserData {
  cargo: string;
  nivelAcesso: string;
  codigoCargo: number;
  dataLogin: string;
  lojas: number[];
  matricula: string;
  nome: string;
  permissoes: PermissoesProps[];
}

interface EstaLogadoResponse {
  result: string;
  colaborador: UserData;
}

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [username, setUsername] = useState("");
  const [matricula, setMatricula] = useState("");
  const [cargo, setCargo] = useState("");
  const [nivelAcesso, setNivelAcesso] = useState("");
  const [permissoes, setPermissoes] = useState<PermissoesProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function handleSession() {
    setIsLoading(true);

    try {
      const storagedHash = localStorage.getItem("@portalAdmin:hash");

      if (storagedHash) {
        const response = await axios.post<EstaLogadoResponse>(
          `/colaborador/EstaLogado`,
          {
            hash: storagedHash,
          }
        );

        if (response.data.result === "Success") {
          const { colaborador } = response.data;
          setUsername(colaborador.nome);
          setCargo(colaborador.cargo);
          setPermissoes(colaborador.permissoes);
          setNivelAcesso(colaborador.nivelAcesso);
          setMatricula(colaborador.matricula);
          return;
        }
      }

      setUsername("");
      setCargo("");
      setPermissoes([]);
    } catch (err: any) {
      console.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSignIn(mat: string, atualizarHash: boolean) {
    const response = await axios.get(`/colaborador/login?matricula=${mat}`);

    if (response.data.result === "Error") {
      alert(response.data.message);
      setIsLoading(false);
      return "";
    }

    const { hash } = response.data;

    if (atualizarHash) localStorage.setItem("@portalAdmin:hash", hash);

    setIsLoading(false);
    return hash;
  }

  function handleSignOut(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setIsLoading(true);

    setUsername("");
    setCargo("");
    setPermissoes([]);

    localStorage.removeItem("@portalAdmin:hash");
  }

  return (
    <AuthContext.Provider
      value={{
        username,
        matricula,
        setUsername,
        cargo,
        nivelAcesso,
        permissoes,
        setPermissoes,
        isLoading,
        handleSession,
        handleSignIn,
        handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };

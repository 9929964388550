import styled from 'styled-components';

import { colors } from "../../tokens";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: ${colors.blue};
    height: 48px;
    color: ${colors.white};
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 4px;
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  width: 924px;
  margin-left: 20px;

  .titleContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;

      div {
        > h2 {
          font-family: "Source Sans Pro", sans-serif;
          font-style: normal;
          font-size: 22px;
          font-weight: 600;
          color: ${colors.dark};
          margin: 0;
        }
      }
  }

  .grid {
    margin-top: 25px;
  }
`;
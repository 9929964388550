import { Form } from "@unform/web";
import Button from "components/Button";
import { Grid } from "components/Grid";
import { NoResults } from "components/NoResults";
import React, { useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { cpfMask, validateCpf } from "components/ValidationCpf";
import * as S from "./styles";
import { ModalHistoricoConsulta } from "components/ModalGroup/ModalHistoricoConsulta";
import { useLoader } from "hooks/loader";

import { InputText } from 'primereact/inputtext';

import axios from "axios";
import IconAtencao from "images/icons/icon-atencao.svg";

function MessageError(message: string, width: string) {
  return (
    <div
      style={{
        width: width,
        height: "32px",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        background: "#FFE4E4",
        marginTop: "8px",
        marginLeft: ".2rem"
      }}
    >
      <img
        src={IconAtencao}
        alt="atencao"
        style={{
          width: "16px",
          height: "16px",
          marginRight: "4px",
        }}
      />
      <span
        style={{
          color: "#D14A4A",
          fontSize: "14px",
        }}
      >
        {message}
      </span>
    </div>
  );
}

interface ParceiroProps {
  parceria: string;
  tipo: string;
}

interface BeneficioProps {
  id: number;
  codigo: string;
  servico: string;
  renovaEm: string;
  quantidade: string;
}

export function ClientesAbaConsultar() {
  const { handleSetIsLoader } = useLoader();
  const [openModalHistorico, setOpenModalHistorico] = useState(false);
  const [isCpfState, setIsCpfState] = useState("");
  const [cpfIsValid, setCpfIsValid] = useState(false);
  const [cpfNotFound, setCpfNotFound] = useState(false);
  const [beneficios, setBeneficios] = useState<BeneficioProps[]>([]);
  const [parcerias, setParcerias] = useState<ParceiroProps[]>([]);
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  function handleMaskCpf(e: React.ChangeEvent<HTMLInputElement>) {
    if (cpfIsValid || cpfNotFound) {
      setCpfIsValid(false);
      setCpfNotFound(false);
    }
    setIsCpfState(cpfMask(e.target.value));
  }

  function formatParcerias(value: []) {
    let parceriasAux: ParceiroProps[] = [];
    value?.map((item: any) => {
      let parceiro = {} as ParceiroProps;
      parceiro.parceria = item.nome;
      parceiro.tipo = item.tipoParceria.nome;
      parceriasAux.push(parceiro);
    });
    setParcerias(parceriasAux);
  }

  function onFocusOutCPF(event: React.FocusEvent<HTMLInputElement>) {
    if (event.target.value === "")
      clearData();
  }

  async function getBeneficios() {
    const cpfClient = isCpfState;

    handleSetIsLoader(true);
    setIsLoading(true);
    clearData();

    const response = await axios.get(`/beneficioData/cpf/${cpfClient}`);

    setIsLoading(false);
    handleSetIsLoader(false);
    if (response.data.result === "Error") {
      setCpfNotFound(true);
      return;
    }

    const { nome, cpf } = response.data.beneficioCliente;

    // console.log(response.data.beneficioCliente.beneficios);
    // console.log(response.data.beneficioCliente.parcerias);

    setCpf(cpf);
    setNome(nome);

    //setBeneficios(response.data.beneficioCliente.beneficios);
    let beneficios: BeneficioProps[] = [];
    response.data.beneficioCliente.beneficios?.map((item: any) => {
      let beneficio = {} as BeneficioProps;
      beneficio.id = item.id;
      beneficio.codigo = item.codigo;
      beneficio.servico = item.servico;
      beneficio.renovaEm = item.renovaEm;
      beneficio.quantidade =
        item.quantidadeDisponivel + " de " + item.quantidadeTotal;
      beneficios.push(beneficio);
    });
    setBeneficios(beneficios);

    formatParcerias(response.data.beneficioCliente.parcerias);
  }

  function handleBuscar(value: string) {
    if (!validateCpf(value)) {
      setCpfIsValid(true);
      return;
    }

    clearData();
    clearMessages();
    getBeneficios();
  }

  const clearData = () => {
    setCpf("");
    setNome("");
    setBeneficios([]);
    formatParcerias([]);
  }

  const clearMessages = () => {
    setCpfIsValid(false);
    setCpfNotFound(false);
  }

  const clearAllData =() => {
    clearData();
    clearMessages();
    setIsCpfState("");
  }

  return (
    <>
      <S.Container>
        <Form onSubmit={() => {}}>
          <div className="formRow">
            <p>
              Para verificar as informações de um CPF, basta digitar o número
              correspondente abaixo e clicar em consultar.
            </p>
          </div>
          <div className="formRow">
            <div className="inputCpf">
              <div>
                <h5>Consultar CPF</h5>
                <div className="p-fluid p-formgrid p-grid">
                  <div className="p-field p-col-12 p-md-4 p-pl-2 p-pt-0 p-pb-0">
                    <InputText
                      style={{ marginLeft: '.2rem' }}
                      id="withoutgrouping"
                      placeholder="Ex.: 000.000.000-00"
                      value={isCpfState}
                      maxLength={14}
                      onBlur={onFocusOutCPF}
                      onChange={(event) => handleMaskCpf(event)}
                    />
                    {cpfNotFound && MessageError("CPF não encontrado", "256px")}
                    {cpfIsValid && MessageError("CPF inválido", "256px")}
                  </div>
                  <div className="p-field p-col-12 p-md-8 p-pl-0 p-pt-0 p-pb-0">
                    <div className="p-formgroup-inline">
                      <div className="p-field">
                        <Button
                          style={{
                            maxWidth: 'unset',
                            height: "42px",
                            padding: "0",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          disabled={cpfIsValid}
                          onClick={() => handleBuscar(isCpfState)}
                          inverted={isLoading}
                        >
                          {isLoading && <>Carregando...</>}
                          {!isLoading && <>Consultar</>}
                        </Button>
                      </div>
                      <div className="p-field">
                      <Button
                          style={{
                            maxWidth: 'unset',
                            height: "42px",
                            padding: "0",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          disabled={isLoading}
                          onClick={() => clearAllData()}
                          inverted
                        >
                          Limpar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="formRow">
            <div className="cpfNome">
              <div
                style={{
                  width: "125px",
                }}
              >
                <label>CPF</label>
                <span>{cpf ? cpf : "-"}</span>
              </div>
              <div>
                <label>Nome</label>
                <span>{nome ? nome : "-"}</span>
              </div>
            </div>
          </div>
          <div className="formRow">
            <div className="labels-grid-beneficios">
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#2A333E",
                }}
              >
                Benefícios
              </label>
              <p
                style={{
                  fontFamily: "Source Sans Pro",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "15px",
                  textDecorationLine: "underline",
                  color: "#0054A6",
                  cursor: "pointer"
                }}
                onClick={() => setOpenModalHistorico(true)}
              >
                Histórico de consumo
              </p>
            </div>
            <Grid
              columns={[
                "Código",
                "Serviços farmacêuticos",
                "Renova em",
                "Qtd",
              ]}
              key={"id"}
              omit={["id"]}
              columnsWidth={[
                {
                  column: "Código",
                  width: "83px",
                },
                {
                  column: "Serviços farmacêuticos",
                  width: "493px",
                },
                {
                  column: "Renova em",
                  width: "197px",
                },
                {
                  column: "Qtd",
                  width: "91px",
                },
              ]}
              data={beneficios}
              customStyle={{
                minHeight: "70px",
                overflow: "hidden",
              }}
              maxHeight={"auto"}
              className={"d-block grid-custom"}
              minHeight={!beneficios.length ? '0' : '300px'}
            />
            {!beneficios.length && (
              <NoResults
                title=""
                message="Nenhuma informação para ser exibida"
                messageStyle={{
                  width: "230px",
                  fontFamily: "Source Sans Pro",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  color: "#404F61",
                }}
              />
            )}
          </div>
          <div className="formRow">
            <div className="parceriaTable">
              <Grid
                columns={["Parceria", "Tipo"]}
                key={"id"}
                columnsWidth={[
                  {
                    column: "Parceria",
                    width: "711px",
                  },
                  {
                    column: "Tipo",
                    width: "150px",
                  },
                ]}
                columnsAlignCenter={[
                  {
                    column: "Parceria",
                    align: false,
                  },
                  {
                    column: "Tipo",
                    align: true
                  },
                ]}
                data={parcerias}
                customStyle={{
                  minHeight: "70px",
                  overflow: "hidden",
                }}
                maxHeight={"auto"}
                className={"d-block grid-custom"}
                minHeight={!parcerias.length ? '0' : '300px'}
              />
              {!parcerias.length && (
                <NoResults
                  title=""
                  message="Nenhuma informação para ser exibida"
                  messageStyle={{
                    width: "230px",
                    fontFamily: "Source Sans Pro",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    color: "#404F61",
                  }}
                />
              )}
            </div>
          </div>
        </Form>
      </S.Container>

      {openModalHistorico && (
        <ModalHistoricoConsulta
          isOpen={openModalHistorico}
          onRequestClose={() => setOpenModalHistorico(false)}
          cpf={isCpfState}
        />
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useLoader } from "hooks/loader";

import { Card } from 'primereact/card';

import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import Button from "components/Button";
import { Tab } from "components/Tab";
import { colors } from "tokens";
import { CadastroParceriaAbaBeneficios } from "components/CadastroParceriaAbaBeneficios";
import { HistoricEditions } from "components/ModalGroup/HistoricEditions";

import * as S from "./styles";
import { mask } from "utils";

function InformacoesIcon() {
  return (
    <svg
      width="15"
      height="18.33"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8333 2.50004H10.35C10 1.53337 9.08333 0.833374 8 0.833374C6.91667 0.833374 6 1.53337 5.65 2.50004H2.16667C1.25 2.50004 0.5 3.25004 0.5 4.16671V17.5C0.5 18.4167 1.25 19.1667 2.16667 19.1667H13.8333C14.75 19.1667 15.5 18.4167 15.5 17.5V4.16671C15.5 3.25004 14.75 2.50004 13.8333 2.50004ZM8 2.50004C8.45833 2.50004 8.83333 2.87504 8.83333 3.33337C8.83333 3.79171 8.45833 4.16671 8 4.16671C7.54167 4.16671 7.16667 3.79171 7.16667 3.33337C7.16667 2.87504 7.54167 2.50004 8 2.50004ZM2.16667 16.6667C2.16667 17.125 2.54167 17.5 3 17.5H13C13.4583 17.5 13.8333 17.125 13.8333 16.6667V5.00004C13.8333 4.54171 13.4583 4.16671 13 4.16671H12.1667V5.00004C12.1667 5.91671 11.4167 6.66671 10.5 6.66671H5.5C4.58333 6.66671 3.83333 5.91671 3.83333 5.00004V4.16671H3C2.54167 4.16671 2.16667 4.54171 2.16667 5.00004V16.6667Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

function BeneficioIcon() {
  return (
    <svg
      width="16.67"
      height="15.83"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3332 5.08337C17.3332 4.15837 16.5915 3.41671 15.6665 3.41671H12.3332V1.75004C12.3332 0.825041 11.5915 0.083374 10.6665 0.083374H7.33317C6.40817 0.083374 5.6665 0.825041 5.6665 1.75004V3.41671H2.33317C1.40817 3.41671 0.674837 4.15837 0.674837 5.08337L0.666504 14.25C0.666504 15.175 1.40817 15.9167 2.33317 15.9167H15.6665C16.5915 15.9167 17.3332 15.175 17.3332 14.25V5.08337ZM10.6665 3.41671V1.75004H7.33317V3.41671H10.6665ZM2.33317 5.91671V13.4167C2.33317 13.875 2.70817 14.25 3.1665 14.25H14.8332C15.2915 14.25 15.6665 13.875 15.6665 13.4167V5.91671C15.6665 5.45837 15.2915 5.08337 14.8332 5.08337H3.1665C2.70817 5.08337 2.33317 5.45837 2.33317 5.91671Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

interface HistoricoEdicoesData {
  editadoEm: string;
  editadoPor: string;
  matriculaEditor: string;
}

interface TipoParceria {
  id: number;
  nome: string;
}

interface BeneficiosVinculados {
  id?: number;
  key?: number;
  idParceria?: number;
  quantidade: number;
  idServico: number;
  codigoServico: string;
  nomeServico: string;
  renovacao: number;
  nomeRenovacao: string
  periodicidade?: object;
};

interface ParceiroProps {
  id: number;
  nome: string;
  vigenciaInicio: string;
  vigenciaFim: string;
  tipo: number;
  idTipoParceria: number;
  tipoParceria: TipoParceria;
  urlBaseExterna: string;
  ativo: boolean;
  beneficios: BeneficiosVinculados[];
  historicoEdicoes: HistoricoEdicoesData[];
  utilizarMascara: boolean;
  landingPageCadastro: boolean;
  criadoPor?: Log;
  ultimaAlteracao?: Log;
}

interface Log {
  data: string;
  codigoColaborador: string;
  colaborador: string;
}

interface CadastroParceriaAbaBeneficiosFormData {
  idServico: number;
  quantidade: number;
  renovacao: number;
  nomeRenovacao: string;
}

export function DetalhesParceria() {
  const { handleSetIsLoader } = useLoader();
  const history = useHistory();
  const { state }: { state: any } = useLocation();

  const [activeTab, setActiveTab] = useState<"informacoes" | "beneficios">(
    "informacoes"
  );

  const [openModalHistorico, setOpenModalHistorico] = useState(false);
  const [loadCount, setLoadCount] = useState(0);

  const [parceria, setParceria] = useState({} as ParceiroProps);
  const [
    cadastroParceriaAbaBeneficiosFormData,
    setCadastroParceriaAbaBeneficiosFormData,
  ] = useState({} as CadastroParceriaAbaBeneficiosFormData);

  const searchParams = new URLSearchParams(history.location.search);
  const _id = searchParams.get("id");

  useEffect(() => {
    (async () => {
      handleSetIsLoader(true);
      if (activeTab === "informacoes") {
        const response = await axios.get(
            `/beneficioData/GetParceria/${_id}`
        );
        if (response.data.result === "Error") {
            if (response.data.code === "001" && loadCount <= 3) {
                let count = loadCount + 1;
                setLoadCount(count);
            }
        } else {
          const { parceria } = response.data
          const {beneficios} = parceria

          const beneficiosMap = beneficios.map((beneficio) => {
            return {
              ...beneficio,
              nomeRenovacao: beneficio.periodicidade.nome
            }
          })

          const parceriaMap = {
            ...parceria,
            beneficios: beneficiosMap
          }

          setParceria({
            ...parceriaMap,
            criadoPor: {
              ...response.data.parceria.criadoPor,
              codigoColaborador: response.data.parceria.criadoPor?.codigoColaborador 
                ? mask(
                  response.data.parceria.criadoPor?.codigoColaborador ?? "",
                  "###.###-##"
                )
                : null,
            },
            ultimaAlteracao: {
              ...response.data.parceria.ultimaAlteracao,
              codigoColaborador: response.data.parceria.ultimaAlteracao?.codigoColaborador 
                ? mask(
                  response.data.parceria.ultimaAlteracao?.codigoColaborador,
                  "###.###-##"
                )
                : null,
            },
            vigenciaInicio: response.data.parceria
              ? response.data.parceria?.vigenciaInicio
              : "",
            vigenciaFim: response.data.parceria
              ? response.data.parceria?.vigenciaFim
              : "",
          });
        }

        if(response)
          handleSetIsLoader(false);
      }
      handleSetIsLoader(false);
    })();
  }, [activeTab, loadCount]);

  const handleGoBack = () =>
    history.push("/portaladmin/parcerias");

  async function handleEditPartnership() {
    history.push("/portaladmin/parcerias/editar", {
      data: {
        ...parceria,
      },
    });
  }

  return (
    <>
      <Header />
      <Main>
        <S.DashboardContainer>
          <DashboardUser />
          <Button inverted onClick={handleGoBack} width="100%">
            Voltar
          </Button>
        </S.DashboardContainer>
        <S.Content>
          <h2>DETALHES DA PARCERIA</h2>
          <Card className="contentBody">
            <div className="tabHeader">
              <Tab
                title="Informações"
                selected={activeTab === "informacoes"}
                icon={<InformacoesIcon />}
                onClick={() => setActiveTab("informacoes")}
              />
              <Tab
                title="Benefícios"
                selected={activeTab === "beneficios"}
                icon={<BeneficioIcon />}
                onClick={() => setActiveTab("beneficios")}
              />
            </div>
            {activeTab === "informacoes" ? (
              <div className="p-fluid p-formgrid p-grid details">
                {!parceria && <p>Carregando...</p>}
                {parceria && (
                  <>
                    <div className="p-field p-col-2 p-d-flex p-flex-column">
                      <label><strong>Criado em:</strong></label>
                      <label>{parceria.criadoPor?.data}</label>
                    </div>
                    <div className="p-field p-col-7 p-d-flex p-flex-column">
                      <label><strong>Criado por:</strong></label>
                      <label>{parceria.criadoPor?.colaborador}</label>
                    </div>
                    <div className="p-field p-col-3 p-d-flex p-flex-column">
                      <label><strong>Matrícula:</strong></label>
                      <label>{parceria.criadoPor?.codigoColaborador}</label>
                    </div>
                    <div className="p-field p-col-2 p-d-flex p-flex-column">
                      <label><strong>Tipo:</strong></label>
                      <label>{parceria.tipoParceria?.nome}</label>
                    </div>
                    <div className="p-field p-col-7 p-d-flex p-flex-column">
                      <label><strong>Nome:</strong></label>
                      <label>{parceria.nome}</label>
                    </div>
                    <div className="p-field p-col-3 p-d-flex p-flex-column">
                      <label><strong>Tipo de cadastro:</strong></label>
                      <label>{parceria.tipo === 1 ? "Manual" : "Automático"}</label>
                    </div>
                    <div className="p-field p-col-8 p-d-flex p-flex-column">
                      <label><strong>URL de consulta:</strong></label>
                      <label>{parceria.urlBaseExterna || "-"}</label>
                    </div>
                    <div className="p-field p-col-2 p-d-flex p-flex-column">
                      <label><strong>Início:</strong></label>
                      <label>{parceria.vigenciaInicio}</label>
                    </div>
                    <div className="p-field p-col-2 p-d-flex p-flex-column">
                      <label><strong>Término:</strong></label>
                      <label>{parceria.vigenciaFim}</label>
                    </div>
                  </>
                )}
                {parceria &&
                  parceria.ultimaAlteracao &&
                  parceria.ultimaAlteracao.data && (
                    <>
                      <div className="p-field p-col-2 p-d-flex p-flex-column">
                        <label><strong>Editado em:</strong></label>
                        <label>{parceria.ultimaAlteracao?.data}</label>
                      </div>
                      <div className="p-field p-col-7 p-d-flex p-flex-column">
                        <label><strong>Editado por:</strong></label>
                        <label>{parceria.ultimaAlteracao?.colaborador}</label>
                      </div>
                      <div className="p-field p-col-3 p-d-flex p-flex-column">
                        <label><strong>Matrícula:</strong></label>
                        <label>{parceria.ultimaAlteracao?.codigoColaborador}</label>
                      </div>
                    </>
                  )}
                {parceria &&
                  parceria.ultimaAlteracao &&
                  parceria.ultimaAlteracao.data && (
                    <div className="p-field p-col-12 p-d-flex p-flex-column link">
                      <strong>
                        <a 
                          onClick={() => setOpenModalHistorico(true)}
                        >Histórico de edições</a>
                      </strong>
                    </div>
                  )}
              </div>
            ) : (
              <div className="details">
                <CadastroParceriaAbaBeneficios
                  formDataParceriaParams={parceria}
                  formDataBeneficios={cadastroParceriaAbaBeneficiosFormData}
                  showButtons={false}
                  onChangeFormData={(
                    data: CadastroParceriaAbaBeneficiosFormData
                  ) => {
                    setCadastroParceriaAbaBeneficiosFormData(data);
                  }}
                />
              </div>
            )}

            {activeTab !== "beneficios" && (
              <div className="contentFooter">
                <Button onClick={handleEditPartnership}>Editar</Button>
              </div>
            )}
          </Card>
        </S.Content>
      </Main>
      <Footer />
      {openModalHistorico && (
        <HistoricEditions
          idParceria={state?.data.id}
          isOpen={openModalHistorico}
          onRequestClose={() => setOpenModalHistorico(false)}
        />
      )}
    </>
  );
}

import axios from 'axios'
import {envConfig} from '../config/envConfig'
import {toast} from 'react-toastify'
import {format} from "date-fns-tz";

export const partnerScheduleLockReleaseService = {

    acquirePartnerStates: async (partnerId) => {
        const {BaseAddress, ApiKey} = envConfig.apiURL("ParceiroLojaSala")

        const url = `${BaseAddress}/GetEstadosParceiro/${partnerId}`

        const req = await axios.get(url)

        const {data, status} = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquirePartnerStores: async (partnerId, stateUF = null) => {
        const {BaseAddress, ApiKey} = envConfig.apiURL("ParceiroLojaSala")

        let url = `${BaseAddress}/GetLojasParceiro/${partnerId}`

        if (stateUF !== null) {
            url += `?siglaUf=${stateUF}`
        }

        const req = await axios.get(url)

        const {data, status} = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquirePartnerSchedules: async (filters) => {
        const {partnerId, selectedStores, startDate, endDate, p} = filters

        const url = `/AgendaData/GetAgendamentos?startIndex=0&pageSize=99000&idParceiro=${partnerId}&lojas=${selectedStores.join(',')}&dataInicial=${format(startDate, "dd/MM/yyyy")}%2000:00&dataFinal=${format(endDate, 'dd/MM/yyyy')}%2023:59&periodo=${p}`

        const req = await axios.get(url)

        const {data, status} = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    checkExitingSchedules: async (filters) => {
        const {partnerId, selectedStores, startDate, endDate, startTime, endTime} = filters

        const url = `/AgendaData/ExisteAgendamento?lojas=${selectedStores}&dataInicial=${format(startDate, "dd/MM/yyyy")}&dataFinal=${format(endDate, "dd/MM/yyyy")}&horaInicial=${startTime}&horaFinal=${endTime}&idParceiro=${partnerId}`

        const req = await axios.get(url)

        const {data, status} = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireReasons: async (action) => {
        const method = action === "BLOCK" ? "GetBloqueioMotivos" : "GetLiberacaoMotivos"

        const req = await axios.get(`/AgendaData/${method}`)

        const {data, status} = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    performBulkBlockUnblock: async (filters, action) => {

        const req = await axios.post(`/AgendaData/${action === "BLOCK" ? 'Bloquear' : 'Desbloquear'}`, filters)

        const {data, status} = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    }
}

import { ModalBase } from "../ModalBase";
import Button from "../../Button";
import { useStore } from "hooks/store";
import IconAuthorization from "images/icons/icon-authorization.svg";
import IconClose from "images/icons/icon-close-modal.png";

import { Container } from "./styles";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import SelectDropdown from "components/SelectDropdown";
import { Form } from "@unform/web";

interface StoreData {
  codigo: number;
  nome: string;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleSetStore: (data: StoreData) => void;
}

export function ModalRequestAcess({
  isOpen,
  onRequestClose,
  handleSetStore,
}: ModalProps) {
  const { store } = useStore();

  const [isDisable, setIsDisable] = useState(false);
  const [storeSelected, setStoreSelected] = useState<StoreData>({
    codigo: 0,
    nome: "0",
  });
  const [stores, setStores] = useState<StoreData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("/LojaData/GetAllLojas");
        setStores(response.data.lojas);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  function setSelected(store: { value: number; label: string }) {
    const storeSelect = stores?.find(
      (store) => store.codigo === Number(store.codigo)
    );

    if (!storeSelect) {
      setStoreSelected({
        codigo: 0,
        nome: "",
      });
      setIsDisable(false);
      return;
    }
    setIsDisable(true);
    setStoreSelected({ codigo: store.value, nome: store.label });
  }

  function handleSubmit() {
    handleSetStore(storeSelected);
  }

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container>
        <img
          src={IconClose}
          alt="close"
          onClick={() => onRequestClose()}
          style={{
            position: "absolute",
            right: "15.04px",
            top: "16px",
            cursor: "pointer",
          }}
        />
        <div className="cabecalho">
          <div className="icon">
            <img src={IconAuthorization} alt="icon" />
          </div>
          <strong>Solicitar acesso</strong>
          <span>
            Para prosseguir selecione a loja que deseja solicitar autorização de
            acesso.
          </span>
        </div>

        <div className="body">
          <label
            style={{
              color: "#2A333E",
              marginBottom: "4px",
            }}
          >
            Lojas
          </label>
          {!stores.length && <p>Carregando...</p>}
          {/* {!!stores.length && <select
            name="store"
            value={storeSelected.codigo}
            onChange={(event) => setSelected(event)}
          >
            {!stores.length && (
              <option value={store.codigo}>Carregando...</option>
            )}
            <option value={["0", "Selecionar loja"]}>Selecionar loja</option>
            {stores &&
              stores.length &&
              stores.map((storeItem) => (
                <option key={storeItem.codigo} value={storeItem.codigo}>
                  {storeItem.codigo} - {storeItem.nome}
                </option>
              ))}
          </select>} */}
          {!!stores.length && (
            <Form onSubmit={() => {}}>
              <SelectDropdown
                name="store"
                placeholder="Selecionar loja"
                value={storeSelected.codigo}
                onChange={(event: unknown) => setSelected(event as any)}
                isSearchable
                options={stores.map((store) => ({
                  value: store.codigo,
                  label: store.codigo + ' - ' + store.nome,
                }))}
              />
            </Form>
          )}
        </div>

        <div className="botoes">
          <Button
            className="cancelar"
            inverted
            onClick={() => onRequestClose()}
            width="123px"
          >
            Cancelar
          </Button>
          <Button
            disabled={!isDisable}
            width="120px"
            onClick={() => handleSubmit()}
          >
            Solicitar
          </Button>
        </div>
      </Container>
    </ModalBase>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  max-height: 480px;
  margin: 32px 0 24px 32px;
  overflow-y: auto;
  overflow-x: hidden;

  font-family: 'Source Sans Pro', sans-serif;

  p {
    font-weight: normal;
    font-size: 14px;
    color: #404F61;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }

  .formRow {
     & + .formRow {
       margin-top: 24px;
     }
    .inputCpf {
      button{
        &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
    }

    .labels-grid-beneficios {
      display: flex;
      margin-right: 1rem;
      justify-content: space-between;
    }

    .grid-custom {
      table {
        width: auto !important;
      }
    }

    .cpfNome {
      display: flex;

      label {
        font-weight: 600;
        color: #2A333E;
      }

      span {
        color: #404F61;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-right: 24px;
      }
    }

    .parceriaTable {
      margin-top: 56px;
    }
  }
`;
import React, { Ref, useEffect, useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import { useHistory } from "react-router";
import { isAfter } from "date-fns";
import * as Yup from "yup";
import axios from "axios";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoader } from "hooks/loader";

import { Input } from "components/Input";
import Button from "components/Button";
import { Button as ButtonPrime } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputCalendar } from "components/InputCalendar";
import SelectDropdown from "components/SelectDropdown";
import { SimpleModal } from "components/ModalGroup/SimpleModal";

import errorImg from "images/iconError.png";
import { convert } from "utils";

interface HistoricoEdicoesData {
  editadoEm: string;
  editadoPor: string;
  matriculaEditor: string;
}

interface BeneficiosVinculados {
  id?: number;
  key?: number;
  idParceria?: number;
  quantidade: number;
  idServico: number;
  codigoServico: string;
  nomeServico: string;
  renovacao: number;
}

interface CadastroParceriaAbaParceriaFormData {
  id: number;
  nome: string;
  vigenciaInicio: string;
  vigenciaFim: string;
  tipo: number;
  idTipoParceria: number;
  urlBaseExterna: string;
  ativo: boolean;
  beneficios: BeneficiosVinculados[];
  historicoEdicoes: HistoricoEdicoesData[];
  utilizarMascara?: boolean;
  landingPageCadastro: boolean;
  myHeaders?: MyHeaderProps[];
}

interface DropdownSelectedItem {
  value: number;
  label: string;
}

interface TipoParceriaData {
  id: number;
  nome: string;
}

interface CadastroParceriaAbaParceriaProps {
  formDataParceria: CadastroParceriaAbaParceriaFormData;
  formRef: Ref<FormHandles>;
  onSubmitForm: (data: CadastroParceriaAbaParceriaFormData) => void;
  handleGoForward: () => void;
  handleValidateForm?: (isValid: boolean) => void;
}

interface MyHeaderProps {
  index?: number;
  chave: string;
  valor: string;
}

export function CadastroParceriaAbaParceria({
  formDataParceria,
  formRef,
  onSubmitForm,
  handleGoForward,
  handleValidateForm,
}: CadastroParceriaAbaParceriaProps) {
  const { handleSetIsLoader } = useLoader();
  const history = useHistory();

  const [nome, setNome] = useState(formDataParceria.nome || "");
  const [inicio, setInicio] = useState(
    formDataParceria.vigenciaInicio
      ? new Date(
          convert({
            dateString: formDataParceria.vigenciaInicio,
            pattern: "yyyy-mm-dd",
          })
        )
      : undefined
  );
  const [termino, setTermino] = useState(
    formDataParceria.vigenciaFim
      ? new Date(
          convert({
            dateString: formDataParceria.vigenciaFim,
            pattern: "yyyy-mm-dd",
          })
        )
      : undefined
  );
  const [tipoBeneficio, setTipoBeneficio] = useState(
    formDataParceria.idTipoParceria || 0
  );
  const [tipoCadastro, setTipoCadastro] = useState(formDataParceria.tipo || 0);
  const [urlConsulta, setUrlConsulta] = useState(
    formDataParceria.urlBaseExterna || ""
  );
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [tiposParceria, setTiposParceria] = useState<TipoParceriaData[]>([]);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [headers, setHeaders] = useState<MyHeaderProps[]>(
    formDataParceria.myHeaders || []
  );
  const [counterHeader, setCounterHeader] = React.useState(0);
  const [utilizarMascara, setUtilizarMascara] = React.useState(
    formDataParceria.utilizarMascara ?? true
  );
  const [landingPageCadastro, setLandingPageCadastro] = React.useState(false);

  const preventEnterEvent = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", preventEnterEvent);

    (async () => {
      const responseTipos = await axios.get("/beneficioData/GetAllTipo");

      if (responseTipos.data.result === "Success") {
        setTiposParceria(responseTipos.data.items);
      }
    })();

    return () => {
      window.removeEventListener("keydown", preventEnterEvent);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (tipoCadastro === 1) {
        setUrlConsulta("");
        if (headers?.length >= 1) setHeaders([]);
      }

      await validateForm({
        nome,
        inicio,
        termino,
        tipoBeneficio,
        tipoCadastro,
        urlConsulta,
        headers,
        utilizarMascara,
        landingPageCadastro,
      });
    })();
  }, [
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
  ]);

  const handleGoBack = () => history.goBack();

  async function validateForm({
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
  }: any) {
    setEnableNextButton(false);
    if (handleValidateForm) handleValidateForm(false);

    if (isAfter(inicio, termino)) return;

    const schema = Yup.object().shape({
      nome: Yup.string().required(),
      vigenciaInicio: Yup.string().required(),
      vigenciaFim: Yup.string().required(),
      tipoBeneficio: Yup.number().required().min(1),
      tipoCadastro: Yup.number().required().min(1),
      urlBaseExterna: Yup.string().when("tipoCadastro", {
        is: (tipoCadastro: number) => tipoCadastro === 2,
        then: Yup.string().required(),
      }),
      utilizarMascara: Yup.bool().required(),
      landingPageCadastro: Yup.bool().required(),
      myHeaders: Yup.array().of(
        Yup.object().shape({
          chave: Yup.string().required(),
          valor: Yup.string().required(),
        })
      ),
    });

    await schema.validate(
      {
        nome,
        vigenciaInicio: inicio,
        vigenciaFim: termino,
        tipoBeneficio,
        tipoCadastro,
        urlBaseExterna: urlConsulta,
        myHeaders: headers,
        utilizarMascara,
        landingPageCadastro,
      },
      {
        abortEarly: false,
      }
    );

    if (handleValidateForm) handleValidateForm(true);
    setEnableNextButton(true);
  }

  const removeHeader = (index: number) => {
    setHeaders((prevIndexes) => [
      ...prevIndexes.filter((item) => item.index !== index),
    ]);
    setCounterHeader((prevCounterHeader) => prevCounterHeader - 1);
  };

  const changeText = (index: number, chave: boolean, text: string) => {
    let newArr = [...headers];

    if (chave) newArr[index].chave = text;
    else newArr[index].valor = text;
    setHeaders(newArr);
  };

  return (
    <div className="form">
      <Form
        ref={formRef}
        onSubmit={(data: CadastroParceriaAbaParceriaFormData) => {
          data.myHeaders = headers;
          data.utilizarMascara = utilizarMascara;
          data.landingPageCadastro = landingPageCadastro;

          handleGoForward();
          onSubmitForm(data);
        }}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div id="nome" className="p-field p-col-12 required">
            <label htmlFor="nome">Nome</label>
            <Input
              name="nome"
              value={nome}
              onChange={(event) => setNome(event.target.value)}
              maxLength={40}
              placeholder="Insira o nome com limite de 40 caracteres"
            />
          </div>
          <div id="inicio" className="p-field p-col required">
            <label htmlFor="Inicio">Início</label>
            <InputCalendar
              name="vigenciaInicio"
              placeholder="00/00/0000"
              isRange={false}
              defaultDateSelected={inicio}
              handleDateChange={(event) => {
                const date = event as Date;
                if (termino && isAfter(date, termino)) {
                  setIsModalErrorOpen(true);
                }
                setInicio(date as Date);
              }}
            />
          </div>
          <div id="termino" className="p-field p-col required">
            <label htmlFor="termino">Término</label>
            <InputCalendar
              name="vigenciaFim"
              defaultDateSelected={termino}
              placeholder="00/00/0000"
              isRange={false}
              handleDateChange={(event) => {
                const date = event as Date;
                if (inicio && isAfter(inicio, date)) {
                  setIsModalErrorOpen(true);
                }
                setTermino(date as Date);
              }}
            />
          </div>
          <div id="tipoBeneficio" className="p-field p-col required">
            <label htmlFor="tipoBeneficio">Tipo</label>
            <SelectDropdown
              name="idTipoParceria"
              placeholder="Tipo do benefício"
              newValue={{
                value: tipoBeneficio,
                label:
                  tiposParceria.find((tipo) => tipo.id === tipoBeneficio)
                    ?.nome || "Tipo do benefício",
              }}
              options={tiposParceria.map((tipo) => ({
                value: tipo.id,
                label: tipo.nome,
              }))}
              onChange={(event: any) => setTipoBeneficio(event.value)}
            />
          </div>
          <div className="p-field p-col-12 p-m-0" style={{ opacity: 0 }}></div>
          <div id="tipoCadastro" className="p-field p-col-4 required">
            <label htmlFor="tipoCadastro">Tipo de Cadastro</label>
            <SelectDropdown
              name="tipo"
              placeholder="Selecione"
              newValue={{
                value: tipoCadastro,
                label:
                  [
                    {
                      value: 1,
                      label: "Manual",
                    },
                    {
                      value: 2,
                      label: "Automático",
                    },
                  ].find((tipo) => tipo.value === tipoCadastro)?.label ||
                  "Selecione",
              }}
              options={[
                {
                  value: 1,
                  label: "Manual",
                },
                {
                  value: 2,
                  label: "Automático",
                },
              ]}
              onChange={(event: any) => {
                setTipoCadastro(event.value);
                setHeaders([]);
                setCounterHeader(0);
              }}
            />
          </div>
          <div id="utilizarMascara" className="p-field p-col-4 required">
            <label htmlFor="utilizarMascara">Utilizar Máscara?</label>
            <div className="p-formgroup-inline">
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="utilizarMascaraSim"
                  name="utilizarMascara"
                  value={utilizarMascara}
                  onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                  checked={utilizarMascara}
                />
                <label htmlFor="utilizarMascaraSim">Sim</label>
              </div>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="utilizarMascaraNao"
                  name="utilizarMascara"
                  value={utilizarMascara}
                  onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                  checked={!utilizarMascara}
                />
                <label htmlFor="utilizarMascaraNao">Não</label>
              </div>
            </div>
          </div>
          <div id="landingPageCadastro" className="p-field p-col-4 required">
            <label htmlFor="landingPageCadastro">
              Utilizar landing page de cadastro de clientes?
            </label>
            <div className="p-formgroup-inline">
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="landingPageCadastroSim"
                  name="landingPageCadastro"
                  value={landingPageCadastro}
                  onChange={(e) => setLandingPageCadastro(!landingPageCadastro)}
                  checked={landingPageCadastro}
                />
                <label htmlFor="landingPageCadastroSim">Sim</label>
              </div>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="landingPageCadastroNao"
                  name="landingPageCadastro"
                  value={landingPageCadastro}
                  onChange={(e) => setLandingPageCadastro(!landingPageCadastro)}
                  checked={!landingPageCadastro}
                />
                <label htmlFor="landingPageCadastroNao">Não</label>
              </div>
            </div>
          </div>
          <div id="urlConsulta" className="p-field p-col-12">
            <label htmlFor="urlConsulta">URL de consulta</label>
            <Input
              name="urlBaseExterna"
              value={urlConsulta}
              onChange={(event) => setUrlConsulta(event.target.value)}
              placeholder="Insira a url de consulta"
              disabled={tipoCadastro !== 2}
            />
          </div>
          <div
            className={classNames({
              "p-field p-col-12 p-m-0": true,
              "p-d-none": tipoCadastro !== 2,
            })}
            style={{ opacity: 0 }}
          ></div>
          {headers?.map((header) => {
            if (header)
              return (
                <React.Fragment key={header.index}>
                  <div
                    id="myHeaders"
                    className={classNames({
                      "p-field p-col-4 required": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                  >
                    <label htmlFor={`chave${header.index}`}>Chave</label>
                    <InputText
                      name={`chave${header.index}`}
                      value={header.chave}
                      onChange={(e) =>
                        changeText(header.index ?? -1, true, e.target.value)
                      }
                      placeholder="Insira a chave da requisição"
                      disabled={tipoCadastro !== 2}
                    />
                  </div>
                  <div
                    className={classNames({
                      "p-field p-col required": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                  >
                    <label htmlFor={`valor${header.index}`}>Valor</label>
                    <div className="p-formgroup-inline">
                      <div className="p-field flex-1">
                        <InputText
                          name={`valor${header.index}`}
                          value={header.valor}
                          onChange={(e) =>
                            changeText(
                              header.index ?? -1,
                              false,
                              e.target.value
                            )
                          }
                          placeholder="Insira o valor da chave da requisição"
                          disabled={tipoCadastro !== 2}
                        />
                      </div>
                      <ButtonPrime
                        type="button"
                        icon="pi pi-trash"
                        className="custom-btn-remove p-button-danger"
                        onClick={() => removeHeader(header.index ?? -1)}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames({
                      "p-field p-col-12 p-m-0": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                    style={{ opacity: 0 }}
                  ></div>
                </React.Fragment>
              );
          })}
          <div
            className={classNames({
              "p-field p-col-3": true,
              "p-d-none": tipoCadastro !== 2,
            })}
            id="tipoCadastro"
          >
            <ButtonPrime
              type="button"
              label="Adicionar Header"
              icon="pi pi-check"
              disabled={counterHeader >= 3}
              className="backgroud-color-default"
              onClick={(_) => {
                _.preventDefault();
                setHeaders((values) => [
                  ...values,
                  { index: counterHeader, chave: "", valor: "" },
                ]);
                setCounterHeader((prevCounterHeader) => prevCounterHeader + 1);
              }}
            />
          </div>
        </div>
        <div className="contentFooter">
          <Button inverted onClick={handleGoBack}>
            Cancelar
          </Button>
          <Button disabled={!enableNextButton}>Avançar</Button>
        </div>
      </Form>

      <SimpleModal
        isOpen={isModalErrorOpen}
        onRequestClose={() => setIsModalErrorOpen(false)}
        confirm={() => setIsModalErrorOpen(false)}
        icon={errorImg}
        title="Erro"
        message="A data inicial deve ser menor que a data final."
        continueButtonText="Fechar"
      />
    </div>
  );
}

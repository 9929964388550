import axios from 'axios'
import axiosConfig from '../config/axiosConfig'
import { envConfig } from '../config/envConfig'
import { authorizationService } from './authorizationService'
import { toast } from 'react-toastify'
import { format } from 'date-fns-tz'

export const partnerDashboardService = {

    acquireStates: async (partnerId) => {
        const { BaseAddress, ApiKey } = envConfig.apiURL("Loja")

        const req = await axios.get(`${BaseAddress}/GetEstados?idParceiro=${partnerId}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireCities: async (stateUF, partnerId) => {
        const { BaseAddress, ApiKey } = envConfig.apiURL("Loja")

        const req = await axios.get(`${BaseAddress}/GetCidades?uf=${stateUF}&parceiro=true&idParceiro=${partnerId}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireNeighborhoods: async (cities) => {
        const { BaseAddress, ApiKey } = envConfig.apiURL("Loja")

        const req = await axios.get(`${BaseAddress}/GetBairros?cidades=${cities}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireStores: async (cities, partnerId) => {
        const { BaseAddress, ApiKey } = envConfig.apiURL("Loja")

        const req = await axios.get(`${BaseAddress}/GetLojasCidades?cidades=${cities.join(',')}&parceiro=True&idParceiro=${partnerId}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireDashboardData: async (filters) => {
        const { startDate, endDate, partnerId, selectedStores } = filters
        const { BaseAddress, ApiKey } = envConfig.apiURL("Agendamento")

        const req = await axios.get(
            `${BaseAddress}/GetAgendamentos?startIndex=0&pageSize=99000&dataInicial=${format(startDate, "dd/MM/yyyy")
            }&dataFinal=${format(endDate, "dd/MM/yyyy")
            }&idParceiro=${partnerId}&lojas=${selectedStores.join(',')
            }`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireScheduleDetails: async (scheduleId) => {
        const req = await axios.get(`/AgendamentoData/GetAgendamento/${scheduleId}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireAvailableDates: async (serviceIds, cdLoja) => {
        const req = await axios.get(`/AgendaData/DisponiveisMes?servicos=${serviceIds.join(',')}&codigoLoja=${cdLoja}&parceiro=true`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireAvailableDatePeriods: async (reqData) => {
        const { storeId, serviceIds, date } = reqData

        const req = await axios.get(`/AgendaData/DisponiveisDia?servicos=${serviceIds.join(',')}&codigoLoja=${storeId}&data=${date}&parceiro=true`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireReschedulingReasons: async () => {
        const req = await axios.get('/AgendamentoData/GetReagendamentoMotivos')

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    performServiceRescheduling: async (newScheduleData) => {
        const req = await axios.post('/AgendamentoData/Reagendar', newScheduleData, axiosConfig)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    setScheduleDone: async (scheduleId) => {
        const { BaseAddress, ApiKey } = envConfig.apiURL("Agendamento")

        const req = await axios.put(`${BaseAddress}/ExecutarServico/${scheduleId}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    acquireCancellationReasons: async () => {
        const req = await axios.get('/AgendamentoData/GetCancelamentoMotivos')

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },

    cancelSchedule: async (scheduleId, reasonId, reasonText) => {
        const req = await axios.put(`/AgendamentoData/Cancelar?idAgendamento=${scheduleId}&idMotivo=${reasonId}&motivo=${reasonText}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    },
}

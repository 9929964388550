import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { ModalBase } from "../ModalBase";
import { InputCalendar } from "components/InputCalendar";
import Select from "components/SelectDropdown";
import Button from "components/Button";

import { manha, noite, tarde } from "data/horarios.json";

import Iconagendamento from "images/icons/rescheduling.svg";

import { Container } from "./styles";
import axios from "axios";
import dateFormat from "utils/dateFormat";

interface FormData {
  data: string;
  periodo: string;
  horario: string;
  idParceiro?: number;
}

interface DropdownProps {
  value: string;
  label: string;
}

interface HorariosDisponiveis {
  manha: string[];
  tarde: string[];
  noite: string[];
}

interface AgendamentoSelecionado {
  idAgendamento: number;
  idServicos: number[];
  loja: number;
  idParceiro?: number;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  confirm: (formData: FormData) => void;
  agendamentoSelecionado: AgendamentoSelecionado;
}

export function ModalRescheduling({
  isOpen,
  onRequestClose,
  confirm,
  agendamentoSelecionado
}: ModalProps){
  const [data, setData] = useState("");
  const [periodo, setPeriodo] = useState<"manha" | "tarde" | "noite" | "">("");
  const [horario, setHorario] = useState("");
  const [horarioDisable, setHorarioDisable] = useState(false);
  const [horarioLoading, setHorarioLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [availableHours, setAvailableHours] = useState<HorariosDisponiveis>();

  const formattedAvailableDays = useMemo(() => {
    return availableDays?.map(day => Intl.DateTimeFormat('pt-br', {
      timeZone: 'UTC'
    }).format(new Date(`${day} 00:00:00`)));
  }, [availableDays]);

  const validateForm = useCallback(async () => {
    try {
      const schema = Yup.object().shape({
        data: Yup.string().required(),
        
        horario: Yup.string().required(),
      });
  
      await schema.validate(
        {
          data,
          
          horario,
        },
        {
          abortEarly: false,
        }
      );

      if (!formattedAvailableDays.includes(data)) {
        throw new Error();
      }

      setIsFormValid(true);
    } catch {
      setIsFormValid(false);
    }
  }, [data, periodo, horario]);

  useEffect(() => {
    (async () => {
      try {
        const [responseAvailableDays] = await Promise.all([
          axios.get(`/AgendaData/DisponiveisMes?servicos=${agendamentoSelecionado.idServicos.join(',')}&codigoLoja=${agendamentoSelecionado.loja}&parceiro=${!!agendamentoSelecionado?.idParceiro}`)
          // axios.get(`/AgendaData/DisponiveisDia?servicos=${agendamentoSelecionado.idServicos.join(',')}&codigoLoja=${agendamentoSelecionado.loja}&data=${data || Intl.DateTimeFormat('pt-br', {
          //   timeZone: "UTC"
          // }).format(new Date())}`)
        ]);  

        if (responseAvailableDays.data.result !== "Success") {
            console.log(responseAvailableDays.data.message);
        }

        // if (responseAvailableHours.data.result !== "Success") {
        //     console.log(responseAvailableHours.data.message);
        // }

        setAvailableDays(responseAvailableDays.data.items);
        // if(responseAvailableHours.data.result === "Success"){
        //   const newObject = {
        //     manha: responseAvailableHours.data.manha ?? [],
        //     tarde: responseAvailableHours.data.tarde ?? [],
        //     noite: responseAvailableHours.data.noite ?? []
        //   }
        //   setAvailableHours(newObject);
        // }
      } catch (err) {
          console.log(err);
      }
    })();
  }, [agendamentoSelecionado.idServicos, agendamentoSelecionado.loja, data]);
  
  useEffect(() => {
    validateForm();
  }, [data, periodo, horario, validateForm]);

  async function handleChangeDate(value: Date) {
    setHorarioLoading(true);
    setAvailableHours({manha: [], noite: [], tarde: []});
    setHorario('');

    const dateFormatted = dateFormat(value as Date);
    setData(dateFormatted);
    
    const responseAvailableHours = await fetch(`/agendaData/DisponiveisDia?servicos=${agendamentoSelecionado.idServicos.join(',')}&codigoLoja=${agendamentoSelecionado.loja}&data=${dateFormatted}&parceiro=${!!agendamentoSelecionado?.idParceiro}`, { cache: "no-cache" }).then(response => response.json());
    if(responseAvailableHours.result === "Success"){
      const newObject = {
        manha: responseAvailableHours.manha ?? [],
        tarde: responseAvailableHours.tarde ?? [],
        noite: responseAvailableHours.noite ?? []
      }
      setAvailableHours(newObject);
    }
    setHorarioLoading(false);
  }

  function obterHorariosPorPeriodo() {
    if (!availableHours?.manha) {
      return [];
    }
    
    if (!periodo) {
      return Object.values(availableHours)
        .flat()
        .filter(item => item.item1.includes(":"))
        .map(object => ({
          value: object.item1.replace(":", ""),
          label: object.item1
        })).sort(function (a: any, b: any) {
            return a.value - b.value;
        });
    }
    
    return availableHours[periodo].map(hour => ({
      value: hour.replace(":", ""),
      label: hour
    }));
  }

  return (
    <ModalBase
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Container>
        <Form onSubmit={() => {}}>
          <div className="header">
            <img src={Iconagendamento} alt="logo" />
            <h2>Reagendamento</h2>
            <span>Defina abaixo a nova data e horário para o reagendamento.</span>
          </div>

          <div className="periodoHorario">
            <div className="dateReagendamento" style={{
              width: "164px",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
              <p>Data</p>
              <InputCalendar
                name="date"
                isRange={false}
                title=""
                placeholder="00/00/0000"
                inputStyle={{
                  width: "164px",
                }}
                styleTextColor="#2A333E"
                SetDateValidation={(isValid) => setIsFormValid(isValid)}
                handleDateChange={(event) => {
                  handleChangeDate(event as Date);
                  // const dateFormatted = Intl.DateTimeFormat("pt-br", {
                  //   timeZone: "UTC"
                  // }).format(event as Date);
                  // setData(dateFormatted);
                  // setAvailableHours({manha: [], noite: [], tarde: []});
                  // setHorario('');
                }}
                availableDays={formattedAvailableDays}
              />
              {horarioLoading && (<div className="msgCarregando" style={{ visibility: 'hidden' }}>carregando...</div>)}
              {(availableDays?.length === 0) && (<div className="msgCarregando">carregando...</div>)}
            </div>

            <div style={{
              width: "164px",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
              <p style={{marginBottom:'0px'}}>Horário</p>
              <div className="input" style={{marginTop:'4px'}}>
                <Select 
                  name="horario"
                  placeholder="Selecione"
                  styleSingleValue="#2A333E"
                  options={[
                    ...obterHorariosPorPeriodo(),
                  ]}
                  style={{
                    width: "164px"
                  }}
                  onChange={(event: unknown) => {
                    const eventObj = event as DropdownProps;
                      if (eventObj.label === 'Selecione')
                          setHorario('');
                      else
                          setHorario(eventObj.label);
                  }}
                  disabled={horarioDisable}
                />
                {availableDays?.length === 0 && (<div className="msgCarregando" style={{ visibility: 'hidden' }}>carregando...</div>)}
                {(horarioLoading) && (<div className="msgCarregando">carregando...</div>)}
              </div>
            </div>
          </div>

          <div className="buttons">
            <Button
              inverted
              width = "123px"
              className="cancel"
              onClick = {() => onRequestClose()}
              style={{
                fontFamily: "Source Sans Pro"
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={!isFormValid}
              width = "132px"
              onClick={() => confirm({ data, periodo, horario, idParceiro: agendamentoSelecionado.idParceiro })}
              style={{
                fontFamily: "Source Sans Pro"
              }}
            >
              Prosseguir
            </Button>
          </div>
        </Form>
      </Container>
    </ModalBase>
  )

}
import { useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import axios from "axios";

import Header from "components/Header";
import Main from "components/Main";
import { Footer } from "components/Footer";
import DashboardUser from "components/DashboardUser";
import SelectDropdown from "components/SelectDropdown";
import { Grid } from "components/Grid";
import { NoResults } from "components/NoResults";
import SelectStores from "components/SelectStores";
import { colors } from "tokens";

import successIcon from "images/icons/grid-action-button-success.svg";
import errorIcon from "images/icons/grid-action-button-error.svg";
import successImg from "images/arrowSuccess.png";
import warningImg from "images/arrowWarning.png";

import { Container, DashboardContainer, Content } from "./styles";
import { ServiceLinkStoreDetailsModal } from "components/ModalGroup/ServiceLinkStoreDetailsModal";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import { useStore } from "hooks/store";
import { useLoader } from "hooks/loader";
import { titleCase } from "utils/titleCase";
import { storeService } from "services/storeService";

interface Servico {
  id: number;
  codigo: string;
  servico: string;
  status: number;
}

interface UF {
  sigla: string;
  descricao: string;
}

interface Cidade {
  nome: string;
  descricao: string;
}

interface Loja {
  id: string;
  codigo: string;
  nome: string;
}

interface StoreData {
  codigo: number;
  nome: string;
}

export function LojaServico() {
  const { handleSetIsLoader } = useLoader();
  const { storeList } = useStore();
  const [codigoLoja, setCodigoLoja] = useState(0);
  const [ufs, setUfs] = useState<UF[]>([]);
  const [cidades, setCidades] = useState<Cidade[]>([]);
  const [bairros, setBairros] = useState<string[]>([]);
  const [servicos, setServicos] = useState<Servico[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ufSelected, setUfSelected] = useState("");
  const [cidadeSelected, setCidadeSelected] = useState("");
  const [bairroSelected, setBairroSelected] = useState("");
  const [lojas, setLojas] = useState<Loja[]>([]);
  const [servicoSelected, setServicoSelected] = useState({} as Servico);
  const [storeDatas, setStoreDatas] = useState<StoreData[]>([]);
  const [storesSelected, setStoresSelected] = useState<StoreData[]>([]);
  const [lojasSelecionadas, setLojasSelecionadas] = useState<number[]>([]);
  const [listSelect, setListSelect] = useState<StoreData[]>([]);
  const [singleStore, setSingleStore] = useState(false);

  const [isStoreLinkServiceModalOpen, setIsStoreLinkServiceModalOpen] =
    useState(false);
  const [isLinkWarningModalOpen, setIsLinkWarningModalOpen] = useState(false);
  const [isUnlinkWarningModalOpen, setIsUnlinkWarningModalOpen] =
    useState(false);
  const [isLinkSuccessModalOpen, setIsLinkSuccessModalOpen] = useState(false);
  const [isUnlinkSuccessModalOpen, setIsUnlinkSuccessModalOpen] =
    useState(false);
  const [isPleaseSelectedLojas, setIsPleaseSelectedLojas] = useState(false);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    handleSetIsLoader(true);
    axios.get<Servico[]>(`servicoData/`).then((response: any) => {
      if (response.data.result == "Success") {
        let servicos: Servico[] = [];
        response.data.items.map((item: any) => {
          let servico = {} as Servico;
          servico.id = item.id;
          servico.codigo = item.codProduto;
          servico.servico = item.nomeAbreviado;
          servicos.push(servico);
        });
        setServicos(servicos);
      }
    });
    axios.get("/lojaData/GetEstados").then((response) => {
      if (response.data.result == "Success") {
        setUfs(response.data.items);
      }
    });
    handleSetIsLoader(false);
  }, []);

  function loadCidades(uf: string) {
    handleSetIsLoader(true);
    setUfSelected(uf);
    axios.get(`/lojaData/GetCidades?uf=${uf}`).then((response) => {
      if (response.data.result == "Success") {
        setCidades(response.data.items);
      }
    });
    handleSetIsLoader(false);
  }

  function loadBairros(cidade: string) {
    handleSetIsLoader(true);
    setCidadeSelected(cidade);
    axios
      .get(`/lojaData/GetBairros?uf=${ufSelected}&cidade=${cidade}`)
      .then((response) => {
        if (response.data.result == "Success") {
          setBairros(response.data.items);
        }
      });
    handleSetIsLoader(false);
  }

  function loadLojas() {
    const _lojas = storesSelected.length
      ? storesSelected.map((store) => String(store.codigo))
      : [];
    setLojas([]);
    if (ufSelected !== "" || _lojas.length > 0) {
      handleSetIsLoader(true);
      setIsLoading(true);
      axios
        .get(
          `/lojaData/GetLojas?lojas=${_lojas.join(
            ","
          )}&uf=${ufSelected}&cidade=${cidadeSelected}`
        )
        .then((response) => {
          if (response.data.result == "Success") {
            let lojas: Loja[] = [];
            response.data.items
              .filter((row: any) => {
                if (bairroSelected !== "")
                  return (
                    row.bairro.toLowerCase() === bairroSelected.toLowerCase()
                  );
                else return row;
              })
              .map((item: any) => {
                let loja = {} as Loja;
                loja.id = item.codigo;
                loja.codigo = item.codigo;
                loja.nome = item.nome;
                lojas.push(loja);
              });
            setLojas(lojas);
            let bairros: any[] = [];
            response.data.items.map((item: any) => {
              if (bairros.indexOf(item.bairro) === -1)
                bairros.push(item.bairro);
            });
            setBairros(bairros);
            //loadSelectLojas(lojas);
          }
          handleSetIsLoader(false);
          setIsLoading(false);
        });
    }
  }

  /*
  function loadSelectLojas(lojas: Loja[]) {
    let storeDatas: StoreData[] = [];
    lojas.map((loja: any) => {
      let storeData = {} as StoreData;
      storeData.codigo = loja.codigo;
      storeData.nome = loja.nome;
      storeDatas.push(storeData);
    });
    setStoreDatas(storeDatas);
  }*/

  function vincular() {
    const idServico = servicoSelected.id;
    const codigoLojas = lojasSelecionadas;

    let url = "/LojaData/AddLojas/" + idServico;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(codigoLojas),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          alert(data.message);
        } else if (data.result === "Success") {
          setIsLinkWarningModalOpen(false);
          setIsLinkSuccessModalOpen(true);
        } else {
          alert("Erro back");
        }
      })
      .catch((error) => {
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  function desvincular() {
    const idServico = servicoSelected.id;
    const codigoLojas = lojasSelecionadas;

    let url = "/LojaData/RemoveLojas/" + idServico;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(codigoLojas),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          alert(data.message);
        } else if (data.result === "Success") {
          setIsUnlinkWarningModalOpen(false);
          setIsUnlinkSuccessModalOpen(true);
        } else {
          alert("Erro back");
        }
      })
      .catch((error) => {
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  function handleApplyFilter(data: any) {
    const newDate = { ...data, storesSelected };
  }

  function GridDetails({ id }: { id?: number }) {
    return (
      <span>
        <svg
          width="15"
          height="15"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.167 0.125H0.833333C0.44375 0.125 0.125 0.44375 0.125 0.833333V12.167C0.125 12.5562 0.44375 12.875 0.833333 12.875H12.167C12.5562 12.875 12.875 12.5562 12.875 12.167V0.833333C12.875 0.44375 12.5562 0.125 12.167 0.125ZM4.375 2.95833H2.95833V4.375H4.375V2.95833ZM9.33333 4.375H6.5C6.11042 4.375 5.79167 4.05625 5.79167 3.66667C5.79167 3.27708 6.11042 2.95833 6.5 2.95833H9.33333C9.72292 2.95833 10.0417 3.27708 10.0417 3.66667C10.0417 4.05625 9.72292 4.375 9.33333 4.375ZM9.33333 7.20833H6.5C6.11042 7.20833 5.79167 6.88958 5.79167 6.5C5.79167 6.11042 6.11042 5.79167 6.5 5.79167H9.33333C9.72292 5.79167 10.0417 6.11042 10.0417 6.5C10.0417 6.88958 9.72292 7.20833 9.33333 7.20833ZM6.5 10.0417H9.33333C9.72292 10.0417 10.0417 9.72292 10.0417 9.33333C10.0417 8.94375 9.72292 8.625 9.33333 8.625H6.5C6.11042 8.625 5.79167 8.94375 5.79167 9.33333C5.79167 9.72292 6.11042 10.0417 6.5 10.0417ZM2.95833 5.79167H4.375V7.20833H2.95833V5.79167ZM4.375 8.625H2.95833V10.0417H4.375V8.625ZM1.54167 11.4583H11.4583V1.54167H1.54167V11.4583Z"
            fill="#6B7C8E"
          />
        </svg>
        <button
          type="button"
          onClick={() => {
            if (id !== undefined) setCodigoLoja(id);
            setIsStoreLinkServiceModalOpen(true);
          }}
        >
          Ver detalhe
        </button>
      </span>
    );
  }

  function ActionButtonHeaderModalContent({
    selectedRows,
  }: {
    selectedRows?: number[];
  }) {
    if (selectedRows !== undefined) setLojasSelecionadas(selectedRows);
    return (
      <>
        <span>
          <img src={successIcon} alt="Vincular" />
          <button
            type="button"
            onClick={() => {
              if (lojasSelecionadas.length == 0) {
                setIsPleaseSelectedLojas(true);
              } else {
                setIsLinkWarningModalOpen(true);
              }
            }}
          >
            Vincular
          </button>
        </span>
        <span>
          <img src={errorIcon} alt="Desvincular" />
          <button
            type="button"
            onClick={() => {
              if (lojasSelecionadas.length == 0) {
                setIsPleaseSelectedLojas(true);
              } else {
                setIsUnlinkWarningModalOpen(true);
              }
            }}
          >
            Desvincular
          </button>
        </span>
      </>
    );
  }

  const handleDisabled = (): boolean | undefined => {
    const noServices =
      Object.keys(servicoSelected).length === 0 &&
      servicoSelected.constructor === Object;

    if (!singleStore && (!storesSelected.length || noServices)) {
      return true;
    } else if (singleStore && noServices) {
      return true;
    } else {
      return false;
    }
  };

  function loadStoreList(uf: string, cidade: string, bairro: string) {
    setStoresSelected([]);
    storeService
      .getAllStoreByFilter(uf, cidade, bairro)
      .then((data) => {
        if (data.result === "Success") {
          var lojas = data.items.map(
            (item: any) =>
              ({
                nome: item.nome,
                codigo: Number(item.codigo),
              } as StoreData)
          );

          if (lojas.length === 1) setSingleStore(true);
          setListSelect(lojas);
        }
      })
      .catch((error) => {
        console.warn(error);
        setListSelect([]);
      });
  }

  return (
    <>
      <Header />
      <Main>
        <Container>
          <DashboardContainer>
            <DashboardUser />
          </DashboardContainer>
          <Content>
            <div>
              <h2>VINCULAR SERVIÇOS</h2>
            </div>

            <Form onSubmit={(data) => handleApplyFilter(data)} ref={formRef}>
              <div className="formRow">
                <div className="selectContainer">
                  <label>Estado</label>
                  <SelectDropdown
                    name="estado"
                    placeholder="Selecione um estado"
                    options={ufs.map((uf) => ({
                      value: uf.sigla,
                      label: titleCase(uf.descricao),
                    }))}
                    onChange={(event: any) => {
                      setUfSelected(event?.value);
                      setCidadeSelected("");
                      setBairroSelected("");
                      setServicoSelected({} as Servico);
                      loadCidades(event?.value);
                      loadStoreList(event?.value, "", "");
                    }}
                  />
                </div>
                <div className="selectContainer">
                  <label>Cidade</label>
                  <SelectDropdown
                    name="city"
                    placeholder="Selecione uma cidade"
                    options={cidades.map((cidade) => ({
                      value: cidade.nome,
                      label: titleCase(cidade.descricao),
                    }))}
                    newValue={{
                      label: !cidadeSelected
                        ? "Selecione"
                        : cidades.find(
                            (cidade) => cidade.nome === cidadeSelected
                          )?.descricao,
                      value: cidadeSelected,
                    }}
                    disabled={!ufSelected}
                    onChange={(event: any) => {
                      loadBairros(event?.value);
                      loadStoreList(ufSelected, event?.value, "");
                    }}
                  />
                </div>
                <div className="selectContainer">
                  <label>Bairro</label>
                  <SelectDropdown
                    name="neighborhood"
                    placeholder="Selecione o bairro"
                    options={bairros.map((bairro) => ({
                      value: bairro,
                      label: bairro,
                    }))}
                    newValue={{
                      label: !bairroSelected ? "Selecione" : bairroSelected,
                      value: bairroSelected,
                    }}
                    disabled={!cidadeSelected}
                    onChange={(event: any) => {
                      setBairroSelected(event?.value);
                      loadStoreList(ufSelected, cidadeSelected, event?.value);
                    }}
                  />
                </div>
              </div>

              <div className="formRow">
                <div className="selectContainer">
                  <SelectStores
                    storeList={ufSelected === "" ? storeList : listSelect}
                    storesSelected={storesSelected}
                    handleSelectStore={setStoresSelected}
                  />
                </div>

                <div className="selectContainer">
                  <label>
                    Serviço<span>*</span>
                  </label>
                  <SelectDropdown
                    name="serviceToLink"
                    placeholder="Selecione um serviço"
                    options={servicos.map((service) => ({
                      value: service.id,
                      label: service.servico,
                    }))}
                    onChange={(event: any) => {
                      if (event?.value !== "") {
                        setServicoSelected(
                          servicos.find(
                            (servico) => servico.id === event?.value
                          ) as Servico
                        );
                      } else setServicoSelected({} as Servico);
                    }}
                    newValue={{
                      label: !servicoSelected.id
                        ? "Selecione"
                        : servicoSelected.servico,
                      value: servicoSelected.id,
                    }}
                  />
                </div>

                <div className="selectContainer">
                  <label>&nbsp;</label>
                  <div className={"formActions"}>
                    <button
                      type="button"
                      disabled={handleDisabled()}
                      onClick={() => loadLojas()}
                    >
                      Aplicar
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      className={"clearBtn"}
                      type="button"
                      onClick={() => window.location.reload()}
                    >
                      Limpar
                    </button>
                  </div>
                </div>
              </div>
            </Form>

            <div className="gridContainer">
              {!!lojas.length && !isLoading && (
                <>
                  <p>{lojas.length} lojas em exibição</p>
                  <Grid
                    columns={["Codigo", "Nome"]}
                    omit={["id", "status"]}
                    columnsWidth={[
                      {
                        column: "Codigo",
                        width: "120px",
                      },
                      {
                        column: "Nome",
                        width: "605px",
                      },
                    ]}
                    data={lojas}
                    enableSelect
                    actionButtonHeaderModalContent={
                      <ActionButtonHeaderModalContent />
                    }
                    enableDetailColumn
                    detailContent={<GridDetails />}
                  />
                </>
              )}

              {isLoading && <p>Carregando...</p>}

              {lojas?.length === 0 && !isLoading && (
                <NoResults
                  title="Atenção!"
                  message="Para encontrar as lojas disponíveis é preciso antes filtrar por estado, cidade ou bairro."
                  colorTitle={colors.dark}
                  colorMessage="#404F61"
                  containerStyle={{
                    marginTop: "96px",
                  }}
                  titleStyle={{
                    fontSize: "22px",
                    fontWeight: 600,
                  }}
                  messageStyle={{
                    color: "#404F61",
                    marginTop: "16px",
                    maxWidth: "497px",
                  }}
                />
              )}
            </div>
          </Content>
        </Container>
      </Main>
      <Footer />

      <ServiceLinkStoreDetailsModal
        isOpen={isStoreLinkServiceModalOpen}
        onRequestClose={() => {
          setCodigoLoja(0);
          setIsStoreLinkServiceModalOpen(false);
        }}
        codigoLoja={codigoLoja}
      />

      <SimpleModal
        isOpen={isLinkWarningModalOpen}
        onRequestClose={() => setIsLinkWarningModalOpen(false)}
        confirm={() => {
          vincular();
        }}
        showCancel
        icon={warningImg}
        title="Atenção"
        message={
          <>
            Deseja vincular o serviço de{" "}
            <strong>
              “{servicoSelected?.codigo} - {servicoSelected?.servico}”
            </strong>{" "}
            ficando assim disponível em todas lojas selecionadas?
          </>
        }
      />

      <SimpleModal
        isOpen={isUnlinkWarningModalOpen}
        onRequestClose={() => setIsUnlinkWarningModalOpen(false)}
        confirm={() => {
          desvincular();
        }}
        showCancel
        icon={warningImg}
        title="Atenção"
        message={
          <>
            Deseja desvincular o serviço{" "}
            <strong>
              “{servicoSelected?.codigo} - {servicoSelected?.servico}”
            </strong>{" "}
            das lojas selecionadas?
          </>
        }
      />

      <SimpleModal
        isOpen={isLinkSuccessModalOpen}
        onRequestClose={() => setIsLinkSuccessModalOpen(false)}
        confirm={() => setIsLinkSuccessModalOpen(false)}
        icon={successImg}
        title="Sucesso"
        message={
          <>
            Operação realizada, o serviço{" "}
            <strong>
              “{servicoSelected?.codigo} - {servicoSelected?.servico}”
            </strong>{" "}
            foi vinculado a todas lojas selecionadas.
          </>
        }
        continueButtonText="Fechar"
      />

      <SimpleModal
        isOpen={isUnlinkSuccessModalOpen}
        onRequestClose={() => setIsUnlinkSuccessModalOpen(false)}
        confirm={() => setIsUnlinkSuccessModalOpen(false)}
        icon={successImg}
        title="Sucesso"
        message={
          <>
            Operação realizada, o serviço{" "}
            <strong>
              “{servicoSelected?.codigo} - {servicoSelected?.servico}”
            </strong>{" "}
            foi desvinculado das lojas selecionadas.
          </>
        }
        continueButtonText="Fechar"
      />

      <SimpleModal
        isOpen={isPleaseSelectedLojas}
        onRequestClose={() => setIsPleaseSelectedLojas(false)}
        confirm={() => setIsPleaseSelectedLojas(false)}
        icon={warningImg}
        title="Atenção"
        message={<>Por favor selecione uma ou mais lojas</>}
        continueButtonText="Fechar"
      />
    </>
  );
}

import { Axios as axios } from "../config/axiosConfig";
import { envConfig } from "../config/envConfig";
import { toast } from "react-toastify";
import { authorizationService } from "./authorizationService";

export const partnerScheduleControlService = {
  acquirePartnerStates: async (partnerId, admin = false) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL(
      admin ? "Loja" : "ParceiroLojaSala"
    );

    const url = admin
      ? `${BaseAddress}/GetEstados`
      : `${BaseAddress}/GetEstadosParceiro/${partnerId}`;

    const req = await axios.get(url);

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  acquirePartnerCities: async (partnerId, state) => {
    const {BaseAddress, ApiKey} = envConfig.apiURL("ParceiroLojaSala");

    const url = `${BaseAddress}/GetCidadesParceiro/${partnerId}?uf=${state}`;

    const req = await axios.get(url);

    const {data, status} = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  acquirePartnerStores: async (partnerId, stateUF = null, admin = false) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL(
      admin ? "Loja" : "ParceiroLojaSala"
    );

    let url = admin
      ? `${BaseAddress}/GetLojasCidades`
      : `${BaseAddress}/GetLojasParceiro/${partnerId}`;

    if (stateUF !== null) {
      admin ? (url += `?estados=${stateUF}`) : (url += `?siglaUf=${stateUF}`);
    }

    const req = await axios.get(url);

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  acquirePartnerStoreSchedules: async (partnerId, storeId) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("ParceiroLojaSala");

    const req = await axios.get(
      `${BaseAddress}/GetAgendaLojaSalaConfig/${partnerId}/${storeId}`
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  insertNewSchedulePeriod: async (insertData) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("ParceiroLojaSala");

    const req = await axios.post(
      `${BaseAddress}/AddAgendaLojaSalaConfig`,
      insertData
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  deleteSchedulePeriod: async (partnerId, periodId) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("ParceiroLojaSala");

    const req = await axios.delete(
      `${BaseAddress}/DeletaAgendaLojaSalaConfig?idParceiro=${partnerId}&idAgendaLojaSalaConfig=${periodId}`
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  insertNewRoom: async (roomName, storeId) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("LojaSala");

    const token = await authorizationService.acquireToken();
    if (token) {
      const req = await axios.post(
        `${BaseAddress}/loja/${storeId}/sala`,
        {
          nomeSala: `${roomName}`,
        },
        {
          headers: {
            access_token: token,
            "x-api-key": ApiKey,
          },
        }
      );

      const { data, status } = req;

      if (status === 201) {
        return data;
      } else {
        toast.error(data.message);
        return null;
      }
    } else {
      toast.error("Sem autorização.");
      return null;
    }
  },

  linkStoreToRoom: async (roomId, storeId) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("LojaSala");

    const token = await authorizationService.acquireToken();
    if (token) {
      const req = await axios.post(
        `${BaseAddress}/loja/${storeId}/sala/${roomId}`,
        null,
        {
          headers: {
            access_token: token,
            "x-api-key": ApiKey,
          },
        }
      );

      const { data, status } = req;

      if (status === 200 || status === 201) {
        return true;
      } else {
        toast.error(data.message);
        return false;
      }
    } else {
      toast.error("Sem autorização.");
      return null;
    }
  },

  acquireReasons: async () => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("LojaSala");

    const token = await authorizationService.acquireToken();
    if (token) {
      const req = await axios.get(`${BaseAddress}/motivo`, {
        headers: {
          access_token: token,
          "x-api-key": ApiKey,
        },
      });

      const { data, status } = req;

      if (status === 200) {
        return data;
      } else {
        toast.error(data.message);
        return null;
      }
    } else {
      toast.error("Sem autorização.");
      return null;
    }
  },

  manageRoomStatus: async (sendData, userData) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL("LojaSala");
    const { IdLojaSala, IdDesativacaoSalaMotivo, Motivo, action } = sendData;
    const { cod, name, role } = userData;

    let activation = {
      Adicionar: null,
      IdDesativacaoSalaMotivo,
      Motivo,
    };

    action === "REACTIVATE"
      ? (activation.Adicionar = true)
      : (activation.Adicionar = false);

    const token = await authorizationService.acquireToken();
    if (token) {
      try {
        const req = await axios.put(
          `${BaseAddress}/${IdLojaSala}`,
          activation,
          {
            headers: {
              codigo: cod,
              nome: name,
              cargo: role,
              access_token: token,
              "x-api-key": ApiKey,
            },
          }
        );

        const { data, status } = req;

        if (status === 200 || status === 201 || status === 204) {
          return true;
        } else {
          toast.error(data.message);
          return false;
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        return false;
      }
    } else {
      toast.error("Sem autorização.");
      return false;
    }
  },
};

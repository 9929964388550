const docURL = document.URL.split(".")[0];
const ENVIRONMENT = docURL.includes("localhost")
  ? "LOCAL"
  : docURL == "https://hubsaudeadmindev"
  ? "DEV"
  : docURL == "https://hubsaudeadminhomo"
  ? "HOM"
  : "PROD";

export const envConfig = {
  apiURL: (apiName) => {
    switch (ENVIRONMENT) {
      case "LOCAL": {
        return API_URLS[apiName].local;
      }
      case "DEV": {
        return API_URLS[apiName].dev;
      }
      case "HOM": {
        return API_URLS[apiName].homolog;
      }
      case "PROD": {
        return API_URLS[apiName].prod;
      }
    }
  },

  authURL: () => {
    switch (ENVIRONMENT) {
      case "LOCAL":
      case "DEV":
      case "HOM":
      case "PROD": {
        return "/ParceiroData/login";
      }
    }
  },
};

const API_URLS = {
  Authorizer: {
    local: {
      BaseAddress: "https://hom.apipmenos.com/authorizer/v1/token",
      ApiKey: "DWX58BXW5m5DCANtmaMKb8ouXCCAwmOC2AcK6Gfj",
      Authorization: "SHViU2F1ZGU6U0hWaVpHVnpaWEoyYVdOdg==",
      ExpireMinutes: 5,
    },
    dev: {
      BaseAddress: "https://hom.apipmenos.com/authorizer/v1/token",
      ApiKey: "DWX58BXW5m5DCANtmaMKb8ouXCCAwmOC2AcK6Gfj",
      Authorization: "SHViU2F1ZGU6U0hWaVpHVnpaWEoyYVdOdg==",
      ExpireMinutes: 5,
    },
    homolog: {
      BaseAddress: "https://hom.apipmenos.com/authorizer/v1/token",
      ApiKey: "DWX58BXW5m5DCANtmaMKb8ouXCCAwmOC2AcK6Gfj",
      Authorization: "SHViU2F1ZGU6U0hWaVpHVnpaWEoyYVdOdg==",
      ExpireMinutes: 5,
    },
    prod: {
      BaseAddress: "https://prod.apipmenos.com/authorizer/v1/token",
      ApiKey: "tgBUzcfz7788oEnGzLq2r64TAtSTQSQP6sCV7mwI",
      Authorization: "SHViZGVTYXVkZTpTSFZpWkdWVFlYVmtaVkJ5YjJRPQ==",
      ExpireMinutes: 5,
    },
  },

  Servico: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_SERVICO}/api/Servico`,
      ApiKey: "94M0IS3xL522g4j7DWDm89Rn6xcTuowraNRS8s2N",
    },
    dev: {
      BaseAddress:
        "https://d8kgjg0tcb.execute-api.us-east-1.amazonaws.com/Prod/api/Servico",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://fdrde11pa5.execute-api.us-east-1.amazonaws.com/Prod/api/Servico",
      ApiKey: "94M0IS3xL522g4j7DWDm89Rn6xcTuowraNRS8s2N",
    },
    prod: {
      BaseAddress:
        "https://wknkr987hi.execute-api.sa-east-1.amazonaws.com/Prod/api/Servico",
      ApiKey: "pRDdP9tVvT8h9VfKCf8oL15ZRIAqZemf7LY4xM5X",
    },
  },

  Loja: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_LOJA}/api/LojaServico`,
      ApiKey: null,
    },
    dev: {
      BaseAddress:
        "https://08cn4cdlne.execute-api.us-east-1.amazonaws.com/Prod/api/LojaServico",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://ffwdd4wei8.execute-api.us-east-1.amazonaws.com/Prod/api/LojaServico",
      ApiKey: "UEGarMvUWq67c3G0wyxl61HuQINYp5UE4aFHy0AT",
    },
    prod: {
      BaseAddress:
        "https://8nulwqbq7k.execute-api.sa-east-1.amazonaws.com/Prod/api/LojaServico",
      ApiKey: "pb0gH4itKE5BhnEtDYg3vaBmrIJsAIMoagUo7rZs",
    },
  },

  LojaSala: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_LOJA}/api/LojaSala`,
      ApiKey: null,
    },
    dev: {
      BaseAddress:
        "https://08cn4cdlne.execute-api.us-east-1.amazonaws.com/Prod/api/LojaSala",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://ffwdd4wei8.execute-api.us-east-1.amazonaws.com/Prod/api/LojaSala",
      ApiKey: "UEGarMvUWq67c3G0wyxl61HuQINYp5UE4aFHy0AT",
    },
    prod: {
      BaseAddress:
        "https://8nulwqbq7k.execute-api.sa-east-1.amazonaws.com/Prod/api/LojaSala",
      ApiKey: "pb0gH4itKE5BhnEtDYg3vaBmrIJsAIMoagUo7rZs",
    },
  },

  ParceiroLojaSala: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_LOJA}/api/ParceiroLojaSala`,
      ApiKey: null,
    },
    dev: {
      BaseAddress:
        "https://08cn4cdlne.execute-api.us-east-1.amazonaws.com/Prod/api/ParceiroLojaSala",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://ffwdd4wei8.execute-api.us-east-1.amazonaws.com/Prod/api/ParceiroLojaSala",
      ApiKey: "UEGarMvUWq67c3G0wyxl61HuQINYp5UE4aFHy0AT",
    },
    prod: {
      BaseAddress:
        "https://8nulwqbq7k.execute-api.sa-east-1.amazonaws.com/Prod/api/ParceiroLojaSala",
      ApiKey: "pb0gH4itKE5BhnEtDYg3vaBmrIJsAIMoagUo7rZs",
    },
  },

  Agenda: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_AGENDA}/api/Agenda`,
      ApiKey: "tOJcEsrZtV9MlhddaM1CnahwOuLtTRxF8eo7iMd5",
    },
    dev: {
      BaseAddress:
        "https://yyn5dxquy5.execute-api.us-east-1.amazonaws.com/Prod/api/Agenda",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://1rmh3xlxre.execute-api.us-east-1.amazonaws.com/Prod/api/Agenda",
      ApiKey: "tOJcEsrZtV9MlhddaM1CnahwOuLtTRxF8eo7iMd5",
    },
    prod: {
      BaseAddress:
        "https://5k384all9b.execute-api.sa-east-1.amazonaws.com/Prod/api/Agenda",
      ApiKey: "ajOzgKxR6y7unMeYUujug5QWfIlghIWN28qsXFSk",
    },
  },

  Cliente: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_CLIENTE}/api/Cliente`,
      ApiKey: "Syflc9gvtC17g1AbSiXB31TJ7BoAj0FF9vfbpTSA",
    },
    dev: {
      BaseAddress:
        "https://ngqxayllrd.execute-api.us-east-1.amazonaws.com/Prod/api/Cliente",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://nacpdid7yf.execute-api.us-east-1.amazonaws.com/Prod/api/Cliente",
      ApiKey: "Syflc9gvtC17g1AbSiXB31TJ7BoAj0FF9vfbpTSA",
    },
    prod: {
      BaseAddress:
        "https://aitih0yhwg.execute-api.sa-east-1.amazonaws.com/Prod/api/Cliente",
      ApiKey: "kjDf3gryV06HzswkloXRh3uXDsufOCE32T8Rtjpt",
    },
  },

  Comunicacao: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_COMUNICACAO}/api/Comunicacao`,
      ApiKey: "YbvJvT50me2zCRxDBFw9Y95MqAJeRxLA5MHY33tk",
    },
    dev: {
      BaseAddress:
        "https://r2x81nrvz3.execute-api.us-east-1.amazonaws.com/Prod/api/Comunicacao",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://xbq20eqzd0.execute-api.us-east-1.amazonaws.com/Prod/api/Comunicacao",
      ApiKey: "YbvJvT50me2zCRxDBFw9Y95MqAJeRxLA5MHY33tk",
    },
    prod: {
      BaseAddress:
        "https://cfolbbd6sd.execute-api.sa-east-1.amazonaws.com/Prod/api/Comunicacao",
      ApiKey: "bijsnL2v2B4RGhWwD91Qv7DnIGBhqiNM51KgQKUX",
    },
  },

  Agendamento: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_AGENDAMENTO}/api/Agendamento`,
      ApiKey: "cHN8ozwVFoBvuGub97gBwC7StRFrjfWBPjj4Og00",
    },
    dev: {
      BaseAddress:
        "https://pqjmz7zws9.execute-api.us-east-1.amazonaws.com/Prod/api/Agendamento",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://0pjrwk9ph2.execute-api.us-east-1.amazonaws.com/Prod/api/Agendamento",
      ApiKey: "cHN8ozwVFoBvuGub97gBwC7StRFrjfWBPjj4Og00",
    },
    prod: {
      BaseAddress:
        "https://ye7i21lmk4.execute-api.sa-east-1.amazonaws.com/Prod/api/Agendamento",
      ApiKey: "FKE3w7y55Z7DrWE6M9EDw5hOZSFxX5Ki2NaaPU6w",
    },
  },

  Login: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_LOGIN}/api/Login`,
      ApiKey: "2j8F1bMjd35zvWjOBtiY4aYqOLPWbhSbtD9cniT9",
    },
    dev: {
      BaseAddress:
        "https://0n7m9brpcb.execute-api.us-east-1.amazonaws.com/Prod/api/Login",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://08h16q0dr8.execute-api.us-east-1.amazonaws.com/Prod/api/Login",
      ApiKey: "2j8F1bMjd35zvWjOBtiY4aYqOLPWbhSbtD9cniT9",
    },
    prod: {
      BaseAddress:
        "https://jlfl9993gk.execute-api.sa-east-1.amazonaws.com/Prod/api/Login",
      ApiKey: "2U4bQZKEMQajinNmgdLT91jhRaxzOo9O2JEom1dt",
    },
  },

  Beneficio: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_BENEFICIO}/api/Beneficio`,
      ApiKey: "vtexappkey-paguemenosqa-BQQGOA",
    },
    dev: {
      BaseAddress:
        "https://r2xml5thrh.execute-api.us-east-1.amazonaws.com/Prod/api/Beneficio",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://fux3mkxfna.execute-api.us-east-1.amazonaws.com/Prod/api/Beneficio",
      ApiKey: "LeAtosnfiL6pg5YwvB8dO9ywJ7IlHBL69UPrCbqj",
    },
    prod: {
      BaseAddress:
        "https://pc4ca1jt81.execute-api.sa-east-1.amazonaws.com/Prod/api/Beneficio",
      ApiKey: "BM8VlTpsDh1uTTNEw9ge02apy5n1rr4Z9SRzZhyq",
    },
  },

  Parceiro: {
    local: {
      BaseAddress: `https://localhost:${process.env.REACT_APP_PORTA_PARCEIRO}/api/Parceiro`,
      ApiKey: null,
    },
    dev: {
      BaseAddress:
        "https://thhzfkcjti.execute-api.us-east-1.amazonaws.com/Prod/api/Parceiro",
      ApiKey: null,
    },
    homolog: {
      BaseAddress:
        "https://8j0nilahei.execute-api.us-east-1.amazonaws.com/Prod/api/Parceiro",
      ApiKey: "tlr4JqStrm3OVlSt8XKsl4uLHsR0uABl7jdiCxdU",
    },
    prod: {
      BaseAddress:
        "https://ae6u5t914g.execute-api.sa-east-1.amazonaws.com/Prod/api/Parceiro",
      ApiKey: null,
    },
  },
};

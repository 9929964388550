import axiosConfig from '../config/axiosConfig'
import { envConfig } from 'config/envConfig'
import axios, { AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { authorizationService } from './authorizationService'
const axiosConfigCustom = Object.assign(axiosConfig, {
  validateStatus: (status: number) => status >= 200 && status < 300
})

export const storeService = {
  getAllStore: async () => {
    const { BaseAddress, ApiKey } = envConfig.apiURL('LojaSala')
    return authorizationService.acquireToken().then(async _token => {
      const req = await axios.get(`${BaseAddress}`, {
        headers: {
          access_token: _token,
          'x-api-key': ApiKey
        }
      })
      const { data, status } = req

      if (status === 200) {
        return data
      } else {
        toast.error(data.message)
        return null
      }
    })
  },
  getAllStoreByFilter: async (
    uf?: string,
    cidade?: string,
    bairro?: string
  ) => {
    let params = {}
    if (uf) params = { ...params, ...{ uf: `${uf}` } }
    if (cidade) params = { ...params, ...{ cidade: `${cidade}` } }
    if (bairro) params = { ...params, ...{ bairro: `${bairro}` } }

    let axiosCustom = { ...axiosConfigCustom } as AxiosRequestConfig
    axiosCustom.params = params

    const req = await axios.get('/lojaData/GetLojas', axiosCustom)
    const { data } = req
    return data
  },
  save: async (info: any) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL('Parceiro')
    const req = await axios.post(`${BaseAddress}/AddLojaSala`, info)
    const { data, status } = req

    if (status === 200) {
      return data
    } else {
      toast.error(data.message)
      return null
    }
  },
  getStoreWithActiveSchedulling: async (idParceiro: number) => {
    const { BaseAddress, ApiKey } = envConfig.apiURL('ParceiroLojaSala')
    return authorizationService.acquireToken().then(async _token => {
      const req = await axios.get(
        `${BaseAddress}/GetSalasComAgendamentosAtivos/${idParceiro}`,
        {
          headers: {
            access_token: _token,
            'x-api-key': ApiKey
          }
        }
      )
      const { data, status } = req

      if (status === 200) {
        return data
      } else {
        toast.error(data.message)
        return null
      }
    })
  }
}

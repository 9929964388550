import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
      cursor: pointer;
    }

    p {
      font-family:  'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px !important;
      color: #444C56;
      margin: 0 !important;
    }
  }

  div {
    margin-left: 19px;
    height: 40px;
    width: 304px;
    border: 1px solid ${colors.gray.blueLight};
    border-radius: 4px;

    display: flex;
    align-items: center;

    padding: 7px 8.11px;

    input {
      flex: 1;

      &::placeholder {
        color: ${colors.gray.blue};
      }
    }

    svg {
      margin: 8px 2.89px;
    }
  }
`;

import "./servicesGrid.scss";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import StatusIcon, { statusIconMap } from "./Icons/StatusIcon/statusIcon";
import ServiceIcon from "./Icons/ServiceIcon/serviceIcon";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { useRef, useState } from "react";
import { Button } from "devextreme-react/button";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToEcxel } from "devextreme/excel_exporter";
import { format } from "date-fns-tz";
import { usePartnerContext } from "contexts/partnerContextProvider";
import { partnerDashboardService } from "../../../services/partnerDashboardService";
import ScheduleDetailModal from "./ScheduleDetailModal/scheduleDetailModal";
import ResqueduleModal from "./RescheduleModal/resqueduleModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

let min = new Date()
let max = new Date(2022, 11, 10, 0, 0, 0)

function ServicesGrid({ dashData }) {
  const [_, agendamentos, handleDashboardData] = dashData;
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState({});
  const [serviceIds, setServiceIds] = useState<number[]>([]);
  const [storeId, setStoreId] = useState(0);
  const [scheduleId, setScheduleId] = useState(0);
  const [resqueduleModalOpen, setResqueduleModalOpen] = useState(false);
  const [availableReschedulingDates, setAvailableReschedulingDates] = useState([
    format(new Date(), "yyyy-MM-dd"),
    format(new Date(), "yyyy-MM-dd"),
  ]);
  const { startDate, setIsLoading } = usePartnerContext();
  const dataGridRef: any = useRef(undefined);

  function exportGridToExcel() {
    const dataGrid = dataGridRef.current.instance;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      "Agendamentos " + format(startDate, "dd-MM-yyyy")
    );
    exportDataGridToEcxel({
      worksheet: worksheet,
      component: dataGrid,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell?.rowType === "data") {
          if (
            gridCell?.column?.name === "Status" &&
            gridCell?.column?.caption === "Status"
          ) {
            excelCell.value = renderStatusExportingCell(gridCell);
          }
          if (
            gridCell?.column?.name === "Serviços" &&
            gridCell?.column?.caption === "Serviços"
          ) {
            excelCell.value = renderServicesExportingCell(gridCell);
          }
          if (
            gridCell?.column?.name === "Loja" &&
            gridCell?.column?.caption === "Loja"
          ) {
            excelCell.value = renderStoreCell(gridCell, true);
          }
          if (
            gridCell?.column?.name === "Cliente" &&
            gridCell?.column?.caption === "Cliente"
          ) {
            excelCell.value = renderClientNameCell(gridCell, true);
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Agendamentos ${format(startDate, "dd-MM-yyyy")}.xlsx`
        );
      });
    });
    dataGrid.cancel = true;
  }

  const exportGridToPDF = () => {
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      customizeCell: ({ gridCell, pdfCell }) => {
        if (gridCell?.rowType === "data") {
          if (
            gridCell?.column?.name === "Status" &&
            gridCell?.column?.caption === "Status"
          ) {
            pdfCell.content = renderStatusExportingCell(gridCell);
          }
          if (
            gridCell?.column?.name === "Serviços" &&
            gridCell?.column?.caption === "Serviços"
          ) {
            pdfCell.content = renderServicesExportingCell(gridCell);
          }
          if (
            gridCell?.column?.name === "Loja" &&
            gridCell?.column?.caption === "Loja"
          ) {
            pdfCell.content = renderStoreCell(gridCell, true);
          }
          if (
            gridCell?.column?.name === "Cliente" &&
            gridCell?.column?.caption === "Cliente"
          ) {
            pdfCell.content = renderClientNameCell(gridCell, true);
          }
        }
      },
    }).then(() => {
      doc.save(`Agendamentos ${format(startDate, "dd-MM-yyyy")}.pdf`);
    });
  };

  const handleScheduleDetails = async (
    sId: number,
    iDs: number[],
    lojaId: number
  ) => {
    setIsLoading(true);
    setServiceIds(iDs);
    setStoreId(lojaId);
    setScheduleId(sId);

    const details = await partnerDashboardService.acquireScheduleDetails(sId);

    setScheduleDetails(details);
    setDetailsModalOpen(true);
    setIsLoading(false);
  };

  const handleReschedule = async (
    lojaId: number,
    iDs: number[],
    idAgendamento: number
  ) => {    
    setServiceIds(iDs);
    setStoreId(lojaId);
    setScheduleId(idAgendamento);

    const alertMessage = `
            <p><strong>O cliente foi informando do reagendamento?</strong></p>
            <p>Caso não, pare este reagendamento e o informe antes de prosseguir.</p>
            <p>Se o reagendamento é uma solicitação do cliente, basta prosseguir</p>
        `;

    Swal.fire({
      title: "Atenção!",
      html: alertMessage,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Prosseguir",
      cancelButtonText: "Abandonar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const availableDays =
          await partnerDashboardService.acquireAvailableDates(iDs, lojaId);
        
        const { result, items } = availableDays

        if (availableDays !== null && result === "Success") {
          
          if(items.length){
            setResqueduleModalOpen(true);
            setIsLoading(false);
            setAvailableReschedulingDates(items);
          //  min = (items[0]);
          //  max = (items[items.length - 1]);            
            
            return true;
          }             
        }

        toast.warning(
          "Não existem datas disponíveis para estes serviços no momento."
        );
        setIsLoading(false);
        return false;
      }
    });
  };

  const handleSetDone = async (scheduleId) => {
    Swal.fire({
      title: "Atenção!",
      html: "Deseja realmente registrar este agendamento como realizado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Prosseguir",
      cancelButtonText: "Abandonar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const done = await partnerDashboardService.setScheduleDone(scheduleId);

        setIsLoading(false);

        if (done.result === "Success") {
          Swal.fire({
            title: "Execução de Serviços",
            html: "O agendamento foi executado com sucesso!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          }).then(() => {
            handleDashboardData();
          });
        } else {
          Swal.fire({
            title: "Execução de Serviços",
            html: "Houve um problema ao marcar o agendamento como executado, tente novamente em instantes.",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          });
        }
      }
    });
  };

  const handleCancelSchedule = async (scheduleId) => {
    Swal.fire({
      title: "Atenção!",
      html: "Existe um agendamento ativo para o horário selecionado, o cliente foi informando do cancelamento antes de realização desta ação?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Prosseguir",
      cancelButtonText: "Abandonar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const reasons =
          await partnerDashboardService.acquireCancellationReasons();

        setIsLoading(false);

        let items = reasons.items.map((reason) => {
          return reason.descricao;
        });

        items = ["OUTRO", ...items];

        const handleText = async () => {
          const { value: text } = await Swal.fire({
            input: "textarea",
            inputLabel: "Motivo do cancelamento",
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Confirmar",
            inputPlaceholder: "Descreva brevemente o motivo...",
            inputAttributes: {
              "aria-label": "Descreva brevemente o motivo...",
            },
            showCancelButton: false,
            allowOutsideClick: false,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value && value.trim().length) {
                  resolve(null);
                } else {
                  resolve("Por favor, descreva motivo acima");
                }
              });
            },
          });

          return text;
        };

        if (reasons.result === "Success") {
          let text = "";

          const { value: reason } = await Swal.fire({
            title: "Motivo do Cancelamento",
            input: "select",
            inputOptions: Object.assign({}, items),
            inputPlaceholder: "Selecione o motivo",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#0054A6",
            cancelButtonColor: "#ed1d24",
            confirmButtonText: "Prosseguir",
            cancelButtonText: "Abandonar",
            reverseButtons: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value && value.length) {
                  resolve(null);
                } else {
                  resolve("Por favor, selecione o motivo acima");
                }
              });
            },
          });

          if (reason) {
            if (reason === "0") {
              text = await handleText();
            }

            setIsLoading(true);

            const cancel = await partnerDashboardService.cancelSchedule(
              scheduleId,
              parseInt(`${reason}`),
              text
            );

            setIsLoading(false);

            if (cancel.result === "Success") {
              Swal.fire({
                title: "Cancelamento",
                text: "O agendamento foi cancelado com sucesso.",
                icon: "success",
                confirmButtonColor: "#0054A6",
                confirmButtonText: "Entendi",
              }).then(() => {
                handleDashboardData();
              });
            }
          }
        }
      } else {
        Swal.fire({
          title: "Atenção!",
          html: "Para cancelar este agendamento, primeiro entre em contato com o cliente e informe o motivo do cancelamento dos serviços contratados.",
          icon: "error",
          confirmButtonColor: "#0054A6",
          confirmButtonText: "Entendi",
        });
      }
    });
  };

  const renderRowActionButtons = (data) => {
    const { lojaId, servicos: srv, idAgendamento, status } = data.data;

    const iDs = srv?.map((s) => {
      return s.id;
    });

    const actions = [
      { id: 1, text: "Ver detalhes", icon: "find" },
      {
        id: 2,
        text: "Reagendar",
        icon: "event",
        disabled: [2, 3, 4].includes(status),
      },
      {
        id: 3,
        text: "Executar",
        icon: "check",
        disabled: [2, 3, 6, 7].includes(status),
      },
      {
        id: 4,
        text: "Cancelar",
        icon: "trash",
        disabled: [2, 3].includes(status),
      },
    ];

    const handleOptionClick = (e) => {
      switch (e.itemData.id) {
        case 1: {
          handleScheduleDetails(idAgendamento, iDs, lojaId);
          break;
        }

        case 2: {
          handleReschedule(lojaId, iDs, idAgendamento);
          break;
        }

        case 3: {
          handleSetDone(idAgendamento);
          break;
        }

        case 4: {
          handleCancelSchedule(idAgendamento);
          break;
        }
      }
    };

    return (
      <DropDownButton
        text="Gerenciar"
        icon="preferences"
        items={actions}
        style={{ width: "100%" }}
        keyExpr="id"
        displayExpr="text"
        onItemClick={(e) => {
          handleOptionClick(e);
        }}
      />
    );
  };

  const renderStatusCell = (data) => {
    return (
      <div className="servicesGridCell">
        <StatusIcon data={data.data} />
      </div>
    );
  };

  const renderStoreCell = (data: any, exporting: boolean) => {
    if (!exporting) {
      return (
        <div className="servicesGridCell">
          {data.data.lojaId} - {data.data.loja} / {data.data.nomeSala}
        </div>
      );
    }

    return `${data.data.lojaId} - ${data.data.loja} / ${data.data.nomeSala}\n\nENDEREÇO: ${data.data.lojaEndereco}`;
  };

  const renderClientNameCell = (data, exporting) => {
    if (!exporting) {
      return <div className="servicesGridCell">{data.data?.cliente?.nome}</div>;
    }

    return `${data.data?.cliente?.nome}`;
  };

  const renderServicesCell = (data) => {
    const { servicos: srv, cliente } = data.data;
    return (
      <div className="servicesGridCell">
        <ServiceIcon services={srv} clientName={cliente?.nome} />
      </div>
    );
  };

  const renderServicesExportingCell = (data) => {
    const { servicos: srv } = data.data;

    const handleServicesExportingCell = (s) => {
      const srvArray: string[] = [];
      s.forEach((service) => srvArray.push(service.nomeAbreviado));
      return srvArray.join(", ");
    };

    return `${handleServicesExportingCell(srv)}`;
  };

  const renderStatusExportingCell = (data) => {
    const { status } = data.data;
    const icon = () => {
      return statusIconMap.find((iconMap) => iconMap.status == status);
    };

    return `${icon()?.text}`;
  };

  const renderGridCell = (data) => {
    const { value } = data;
    return <div className="servicesGridCell">{value}</div>;
  };

  const renderColumnHeader = (title) => {
    return (
      <strong style={{ color: "#444", textTransform: "uppercase" }}>
        {title}
      </strong>
    );
  };

  const haldleExportingType = (type) => {
    switch (type) {
      case "PDF": {
        exportGridToPDF();
        break;
      }

      case "EXCEL": {
        exportGridToExcel();
        break;
      }
    }
  };

  return (
    <>
      {agendamentos.length > 0 && (
        <div className="servicesGrid" id="servicesGrid">
          <div className="servicesGrid__exportButtons">
            <Button
              className="exportBtn"
              icon="pdffile"
              text="Gerar PDF"
              onClick={() => haldleExportingType("PDF")}
            />
            <Button
              className="exportBtn"
              icon="xlsxfile"
              text="Gerar Excel"
              onClick={() => haldleExportingType("EXCEL")}
            />
          </div>

          <DataGrid
            ref={dataGridRef}
            showColumnLines={false}
            showRowLines={true}
            id="dataGrid"
            dataSource={agendamentos}
            keyExpr="idAgendamento"
            allowColumnResizing={false}
            columnAutoWidth={true}
            onExporting={haldleExportingType}
          >
            <Column
              width="auto"
              name="Status"
              allowExporting={true}
              allowFiltering={false}
              allowSorting={false}
              dataField="status"
              caption="Status"
              headerCellRender={() => renderColumnHeader("Status")}
              cellRender={(data) => renderStatusCell(data)}
            />
            <Column
              width="auto"
              allowSorting={false}
              name="Loja"
              caption="Loja"
              headerCellRender={() => renderColumnHeader("Loja")}
              cellRender={(data) => renderStoreCell(data, false)}
            />
            <Column
              allowSorting={false}
              cellRender={(data) => renderGridCell(data)}
              headerCellRender={() => renderColumnHeader("Data")}
              caption="Data"
              dataField="data"
              width={100}
            />
            <Column
              allowSorting={false}
              cellRender={(data) => renderGridCell(data)}
              headerCellRender={() => renderColumnHeader("Hora")}
              caption="Hora"
              dataField="hora"
              width={100}
            />
            <Column
              width="auto"
              allowSorting={false}
              allowExporting={true}
              name="Cliente"
              caption="Cliente"
              cellRender={(data) => renderClientNameCell(data, false)}
              headerCellRender={() => renderColumnHeader("Cliente")}
            />
            <Column
              name="Serviços"
              allowSorting={false}
              allowExporting={true}
              cellRender={(data) => renderServicesCell(data)}
              headerCellRender={() => renderColumnHeader("Serviços")}
              caption="Serviços"
            />
            <Column
              allowExporting={false}
              width={150}
              headerCellRender={() => renderColumnHeader("Ações")}
              allowSorting={false}
              allowSearch={false}
              cellRender={renderRowActionButtons}
            />
            <FilterRow visible={true} />
            <Selection mode="none" />
          </DataGrid>
        </div>
      )}

      <ScheduleDetailModal
        open={detailsModalOpen}
        setOpen={setDetailsModalOpen}
        callReschedule={handleReschedule}
        callCancel={handleCancelSchedule}
        callSetDone={handleSetDone}
        ids={[serviceIds, storeId, scheduleId]}
        details={scheduleDetails}
      />

      <ResqueduleModal
        open={resqueduleModalOpen}
        setOpen={setResqueduleModalOpen}
        minDate={format(min, 'yyyy-MM-dd')}
        maxDate={format(max, 'yyyy-MM-dd')}        
        ids={[serviceIds, storeId, scheduleId]}
        handleDashboardData={handleDashboardData}
        availableDates={availableReschedulingDates}
      />
    </>
  );
}

export default ServicesGrid;

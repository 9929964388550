import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import { ParceiroFiltroModal } from "components/ModalGroup/ParceiroFiltroModal";
import DashboardUser from "components/DashboardUser";
import { GridFilter } from "components/GridFilter";
import { Grid, StatusIconMapProps } from "components/Grid";
import { Badge } from "../../components/Badge";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import { NoResults } from "components/NoResults";
import { debounce } from "utils/debounce";
import { useLoader } from "hooks/loader";

import { DashboardContainer, Content } from "./styles";

import successImg from "images/arrowSuccess.png";
import warningImg from "images/arrowWarning.png";
import { convert } from "utils/dateConverter";
import ConfigLandingPage from "./ConfigLandingPage/configLandingpage";
import { partnershipLandingPageService } from "services/partnershipLandingPageService";

interface ParceiroProps {
  id: number;
  parcerias: string;
  classificacao: string;
  inicio: string;
  termino: string;
  status: "active" | "inactive";
  tipo: string;
  landingPageCadastro: boolean;
}

interface FilterProps {
  classificacao: string;
  inicio: string;
  termino: string;
}

interface GridActionButtonContentProps {
  data: ParceiroProps;
}

interface SortDirectionProps {
  classificacao: "asc" | "desc";
  inicio: "asc" | "desc";
  termino: "asc" | "desc";
}

export function Parcerias() {
  const history = useHistory();
  const { handleSetIsLoader } = useLoader();
  const [filterDateInicio, setFilterDateInicio] = useState("");
  const [filterDateTermino, setFilterDateTermino] = useState("");
  const [filterClassificacao, setFilterClassificacao] = useState("");
  const [parcerias, setParcerias] = useState<ParceiroProps[]>([]);
  const [sortParcerias, setSortParcerias] = useState<ParceiroProps[]>([]);
  const [openModalFiltro, setOpenModalFiltro] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState({
    classificacao: "asc",
    inicio: "asc",
    termino: "asc",
  } as SortDirectionProps);

  const [selectedParceria, setSelectedParceria] = useState<ParceiroProps>(
    {} as ParceiroProps
  );

  const [
    isModalDeactivatePartnershipOpen,
    setIsModalDeactivatePartnershipOpen,
  ] = useState(false);
  const [
    isModalDeactivatePartnershipSuccessOpen,
    setIsModalDeactivatePartnershipSuccessOpen,
  ] = useState(false);
  const [isModalActivatePartnershipOpen, setIsModalActivatePartnershipOpen] =
    useState(false);
  const [
    isModalActivatePartnershipSuccessOpen,
    setIsModalActivatePartnershipSuccessOpen,
  ] = useState(false);

  const [landingPageData, setLandingPageData] = useState({});
  const [configLandingPage, setConfigLandingPage] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = "unset";
    loadParcerias("", "", "");
  }, []);

  const HandleConfigModal = () => {
    return (
      <ConfigLandingPage
        isOpen={configLandingPage}
        setIsOpen={setConfigLandingPage}
        formData={landingPageData}
      />
    );
  };

  async function handleGetLandingPageData(partnershipData) {
    document.body.style.overflowY = "hidden";
    handleSetIsLoader(true);

    const { id, nome, tipo } = partnershipData;

    const landingPageData =
      await partnershipLandingPageService.getLandingpageData(id);

    if (landingPageData !== null) {
      setLandingPageData({
        id,
        name: nome,
        type: tipo,
        ...landingPageData,
      });

      setConfigLandingPage(true);
    }

    handleSetIsLoader(false);
  }

  async function loadParcerias(
    dataInicial: string,
    dataFinal: string,
    search: string,
    idTipo?: number
  ) {
    handleSetIsLoader(true);
    const [responseParcerias, responseTipos] = await Promise.all([
      axios.get(
        `/beneficioData/GetAllParceria?dataInicial=${dataInicial}&dataFinal=${dataFinal}&search=${search}&idTipo=${
          idTipo || ""
        }`
      ),
      axios.get("/beneficioData/GetAllTipo"),
    ]);

    if (
      responseParcerias.data.result === "Success" &&
      responseTipos.data.result === "Success"
    ) {
      const parcerias = responseParcerias.data.items as ParceiroProps[];

      const formattedParcerias = parcerias.map((item: any) => {
        const findTipo = responseTipos.data.items.find((tipo: any) => {
          return Number(tipo.id) === Number(item.idTipoParceria);
        });

        return {
          ...item,
          nome: item.nome,
          tipo: findTipo ? findTipo.nome : "",
          vigenciaInicio: item.vigenciaInicio,
          vigenciaFim: item.vigenciaFim,
          status: item.ativo ? "active" : "inactive",
          landingPageCadastro: item.landingPageCadastro ? "Sim" : "Não",
        };
      });
      handleSetIsLoader(false);
      setLoading(false);
      setParcerias(formattedParcerias);
      setSortParcerias(formattedParcerias);
    }
  }

  function handleCreateNewPartnership() {
    history.push("/portaladmin/parcerias/cadastro");
  }

  useEffect(() => {
    debounce(() => {
      if (filterText) {
        loadParcerias("", "", filterText);
        return;
      }

      loadParcerias("", "", "");
    }, 500);
  }, [filterText]);

  const statusIconMap: StatusIconMapProps[] = [
    {
      status: "active",
      icon: <Badge type="active" text="Ativo" />,
    },
    {
      status: "inactive",
      icon: <Badge type="inactive" text="Inativo" />,
    },
  ];

  function handleSetFilterData({
    classificacao,
    inicio,
    termino,
  }: FilterProps) {
    loadParcerias(inicio, termino, filterText, Number(classificacao));
  }

  async function handleTogglePartnership() {
    try {
      selectedParceria.status === "active"
        ? await axios.put(
            `/beneficioData/DesactiveParceria/${selectedParceria.id}`,
            {}
          )
        : await axios.put(
            `/beneficioData/ActiveParceria/${selectedParceria.id}`,
            {}
          );

      setSelectedParceria({} as ParceiroProps);
      loadParcerias("", "", filterText);
    } catch (error) {
      console.error(error);
    }
  }

  function handleSortByColumn(column: string) {
    switch (column) {
      case "Classificação":
        setSortParcerias([]);
        setLoading(true);
        setTimeout(() => {
          setSortParcerias(
            parcerias.sort((a: any, b: any) => {
              if (sortDirection.classificacao === "asc") {
                setSortDirection({
                  ...sortDirection,
                  classificacao: "desc",
                });
                return a.tipo < b.tipo ? -1 : 1;
              } else {
                setSortDirection({
                  ...sortDirection,
                  classificacao: "asc",
                });
                return a.tipo > b.tipo ? -1 : 1;
              }
            })
          );
          setLoading(false);
        }, 500);
        break;

      case "Inicio":
        setSortParcerias([]);
        setLoading(true);
        setTimeout(() => {
          setSortParcerias(
            parcerias.sort((a: any, b: any) => {
              const dateA = new Date(
                convert({ dateString: a.vigenciaInicio, pattern: "yyyy-mm-dd" })
              );
              const dateB = new Date(
                convert({ dateString: b.vigenciaInicio, pattern: "yyyy-mm-dd" })
              );

              if (sortDirection.inicio === "asc") {
                setSortDirection({
                  ...sortDirection,
                  inicio: "desc",
                });
                // @ts-ignore
                return dateA - dateB;
              } else {
                setSortDirection({
                  ...sortDirection,
                  inicio: "asc",
                });
                // @ts-ignore
                return dateB - dateA;
              }
            })
          );
          setLoading(false);
        }, 500);
        break;

      case "Término":
        setSortParcerias([]);
        setLoading(true);
        setTimeout(() => {
          setSortParcerias(
            parcerias.sort((a: any, b: any) => {
              const dateA = new Date(
                convert({ dateString: a.vigenciaFim, pattern: "yyyy-mm-dd" })
              );
              const dateB = new Date(
                convert({ dateString: b.vigenciaFim, pattern: "yyyy-mm-dd" })
              );

              if (sortDirection.termino === "asc") {
                setSortDirection({
                  ...sortDirection,
                  termino: "desc",
                });
                // @ts-ignore
                return dateA - dateB;
              } else {
                setSortDirection({
                  ...sortDirection,
                  termino: "asc",
                });
                // @ts-ignore
                return dateB - dateA;
              }
            })
          );
          setLoading(false);
        }, 500);
        break;

      default:
        setSortParcerias(parcerias);
    }
  }

  function ActionButtonModalContent({ data }: GridActionButtonContentProps) {
    const { landingPageCadastro } = data;
    const landing = landingPageCadastro.toString();
    return (
      <>
        <span>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.167 0.125H0.833333C0.44375 0.125 0.125 0.44375 0.125 0.833333V12.167C0.125 12.5562 0.44375 12.875 0.833333 12.875H12.167C12.5562 12.875 12.875 12.5562 12.875 12.167V0.833333C12.875 0.44375 12.5562 0.125 12.167 0.125ZM4.375 2.95833H2.95833V4.375H4.375V2.95833ZM9.33333 4.375H6.5C6.11042 4.375 5.79167 4.05625 5.79167 3.66667C5.79167 3.27708 6.11042 2.95833 6.5 2.95833H9.33333C9.72292 2.95833 10.0417 3.27708 10.0417 3.66667C10.0417 4.05625 9.72292 4.375 9.33333 4.375ZM9.33333 7.20833H6.5C6.11042 7.20833 5.79167 6.88958 5.79167 6.5C5.79167 6.11042 6.11042 5.79167 6.5 5.79167H9.33333C9.72292 5.79167 10.0417 6.11042 10.0417 6.5C10.0417 6.88958 9.72292 7.20833 9.33333 7.20833ZM6.5 10.0417H9.33333C9.72292 10.0417 10.0417 9.72292 10.0417 9.33333C10.0417 8.94375 9.72292 8.625 9.33333 8.625H6.5C6.11042 8.625 5.79167 8.94375 5.79167 9.33333C5.79167 9.72292 6.11042 10.0417 6.5 10.0417ZM2.95833 5.79167H4.375V7.20833H2.95833V5.79167ZM4.375 8.625H2.95833V10.0417H4.375V8.625ZM1.54167 11.4583H11.4583V1.54167H1.54167V11.4583Z"
              fill="#6B7C8E"
            />
          </svg>
          <button
            type="button"
            onClick={() =>
              history.push("/portaladmin/parcerias/detalhes?id=" + data.id, {
                data,
              })
            }
          >
            Ver detalhes
          </button>
        </span>
        {landing === "Sim" && (
          <span>
            <svg width="18" height="18" viewBox="0 0 27 27">
              <path
                fill="#6B7C8E"
                d="M21.7 18.6V17.6L22.8 16.8C22.9 16.7 23 16.6 22.9 16.5L21.9 14.8C21.9 14.7 21.7 14.7 21.6 14.7L20.4 15.2C20.1 15 19.8 14.8 19.5 14.7L19.3 13.4C19.3 13.3 19.2 13.2 19.1 13.2H17.1C16.9 13.2 16.8 13.3 16.8 13.4L16.6 14.7C16.3 14.9 16.1 15 15.8 15.2L14.6 14.7C14.5 14.7 14.4 14.7 14.3 14.8L13.3 16.5C13.3 16.6 13.3 16.7 13.4 16.8L14.5 17.6V18.6L13.4 19.4C13.3 19.5 13.2 19.6 13.3 19.7L14.3 21.4C14.4 21.5 14.5 21.5 14.6 21.5L15.8 21C16 21.2 16.3 21.4 16.6 21.5L16.8 22.8C16.9 22.9 17 23 17.1 23H19.1C19.2 23 19.3 22.9 19.3 22.8L19.5 21.5C19.8 21.3 20 21.2 20.3 21L21.5 21.4C21.6 21.4 21.7 21.4 21.8 21.3L22.8 19.6C22.9 19.5 22.9 19.4 22.8 19.4L21.7 18.6M18 19.5C17.2 19.5 16.5 18.8 16.5 18S17.2 16.5 18 16.5 19.5 17.2 19.5 18 18.8 19.5 18 19.5M12.3 22H3C1.9 22 1 21.1 1 20V4C1 2.9 1.9 2 3 2H21C22.1 2 23 2.9 23 4V13.1C22.4 12.5 21.7 12 21 11.7V6H3V20H11.3C11.5 20.7 11.8 21.4 12.3 22Z"
              />
            </svg>
            <button
              type="button"
              onClick={() => handleGetLandingPageData(data)}
            >
              Configurar landing page
            </button>
          </span>
        )}
        <span>
          {data.status === "active" ? (
            <>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.00008 0.666668C4.39175 0.666668 0.666748 4.39167 0.666748 9C0.666748 13.6083 4.39175 17.3333 9.00008 17.3333C13.6084 17.3333 17.3334 13.6083 17.3334 9C17.3334 4.39167 13.6084 0.666668 9.00008 0.666668ZM10.5751 6.25001L9.00008 7.82501L7.42508 6.25001C7.26939 6.09397 7.05801 6.00627 6.83758 6.00627C6.61715 6.00627 6.40577 6.09397 6.25008 6.25001C5.92508 6.57501 5.92508 7.10001 6.25008 7.42501L7.82508 9.00001L6.25008 10.575C5.92508 10.9 5.92508 11.425 6.25008 11.75C6.57508 12.075 7.10008 12.075 7.42508 11.75L9.00008 10.175L10.5751 11.75C10.9001 12.075 11.4251 12.075 11.7501 11.75C12.0751 11.425 12.0751 10.9 11.7501 10.575L10.1751 9.00001L11.7501 7.42501C12.0751 7.10001 12.0751 6.57501 11.7501 6.25001C11.4251 5.93334 10.8917 5.93334 10.5751 6.25001ZM2.33341 9C2.33341 12.675 5.32508 15.6667 9.00008 15.6667C12.6751 15.6667 15.6667 12.675 15.6667 9C15.6667 5.325 12.6751 2.33333 9.00008 2.33333C5.32508 2.33333 2.33341 5.325 2.33341 9Z"
                  style={{
                    fill: "#F14A50",
                  }}
                />
              </svg>

              <button
                type="button"
                onClick={() => {
                  setSelectedParceria(data);
                  setIsModalDeactivatePartnershipOpen(true);
                }}
                style={{
                  color: "#F14A50",
                }}
              >
                Desativar
              </button>
            </>
          ) : (
            <>
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.97123 7.52332L13.005 0.48957C13.5575 -0.0629297 14.45 -0.0629297 15.0096 0.48957L16.0012 1.48124C16.5537 2.03374 16.5537 2.93332 16.0012 3.48582L6.96998 12.51C6.41748 13.0625 5.51789 13.0625 4.96539 12.51L0.998726 8.54332C0.446226 7.99082 0.446226 7.09124 0.998726 6.53874L1.99039 5.54707C2.54289 4.99457 3.44248 4.99457 3.99498 5.54707L5.97123 7.52332ZM14.4996 2.98291C14.7758 2.70666 14.7758 2.26749 14.4996 1.99124C14.2233 1.71499 13.7841 1.71499 13.5079 1.99124L5.97122 9.52791L3.49205 7.04874C3.2158 6.77249 2.77664 6.77249 2.50039 7.04874C2.36782 7.17968 2.29321 7.35825 2.29321 7.54458C2.29321 7.7309 2.36782 7.90947 2.50039 8.04041L5.47539 11.0154C5.75164 11.2917 6.1908 11.2917 6.46705 11.0154L14.4996 2.98291Z"
                  fill="#237B4B"
                />
              </svg>
              <button
                type="button"
                onClick={() => {
                  setSelectedParceria(data);
                  setIsModalActivatePartnershipOpen(true);
                }}
                style={{
                  color: "#237B4B",
                }}
              >
                Ativar
              </button>
            </>
          )}
        </span>
      </>
    );
  }

  return (
    <>
      <Header />
      <Main>
        <DashboardContainer>
          <DashboardUser />
          <button type="button" onClick={handleCreateNewPartnership}>
            Incluir nova parceria
          </button>
        </DashboardContainer>

        <Content>
          <div className="titleContainer">
            <div>
              <h2>GERENCIAMENTO DE PARCERIAS</h2>
            </div>
            <GridFilter
              showInput
              placeholder="Buscar por parceria"
              onClick={() => setOpenModalFiltro(true)}
              onChange={(event) => setFilterText(event.target.value)}
            />
          </div>
          <div className="grid">
            <Grid
              columns={[
                "Parcerias",
                "Classificação",
                "Inicio",
                "Término",
                "Landing Page",
                "Status",
              ]}
              omit={[
                "id",
                "ativo",
                "dataCadastro",
                "beneficios",
                "urlBaseExterna",
                "idTipoParceria",
                "tipoParceria",
                "criadoPor",
                "ultimaAlteracao",
                "headers",
                "buscaComMascara",
              ]}
              columnsWidth={[
                { column: "Parcerias", width: "250px" },
                { column: "Classificação", width: "132px" },
                { column: "Inicio", width: "110px" },
                { column: "Término", width: "110px" },
                { column: "Landing Page", width: "110px" },
                { column: "Status", width: "128px" },
                { column: "Action", width: "115px" },
              ]}
              maxHeight="560px"
              customStyle={{
                width: "923px",
              }}
              data={sortParcerias}
              statusIconMap={statusIconMap}
              actionButtonModalContent={
                <ActionButtonModalContent data={{} as ParceiroProps} />
              }
              enableActionButton
              enableColumnSort
              disableColumnSortFor={["Parcerias", ""]}
              onClickSort={(event) => handleSortByColumn(event)}
            />
            {!parcerias.length && !loading && (
              <NoResults
                containerStyle={{
                  marginTop: "-200px",
                }}
                title="Sem resultados para esta pesquisa"
                message="Verifique se o termo utilizado para busca está correto"
                titleStyle={{
                  fontFamily: "Source Sans Pro",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#2A333E",
                }}
                messageStyle={{
                  width: "329px",
                  fontFamily: "Source Sans Pro",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#404F61",
                }}
              />
            )}
            {loading && <p style={{ marginTop: "-255px" }}>Carregando...</p>}
          </div>
        </Content>
      </Main>
      <Footer />

      <ParceiroFiltroModal
        isOpen={openModalFiltro}
        onRequestClose={() => setOpenModalFiltro(false)}
        onContinue={(data) => {
          handleSetFilterData(data);

          setFilterDateInicio(data.inicio);
          setFilterDateTermino(data.termino);
          setFilterClassificacao(data.classificacao);
        }}
        defaultData={{
          filterDateInicio,
          filterDateTermino,
          filterClassificacao,
        }}
      />
      <SimpleModal
        isOpen={isModalDeactivatePartnershipOpen}
        title="Desativar parceria"
        message="Deseja continuar com esta operação?"
        icon={warningImg}
        showCancel
        cancelButtonText="Voltar"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => {
          handleTogglePartnership();
          setIsModalDeactivatePartnershipOpen(false);
          setIsModalDeactivatePartnershipSuccessOpen(true);
        }}
        onRequestClose={() => setIsModalDeactivatePartnershipOpen(false)}
      />

      <SimpleModal
        isOpen={isModalDeactivatePartnershipSuccessOpen}
        title="Sucesso"
        message="Parceria foi desativado com sucesso."
        icon={successImg}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => setIsModalDeactivatePartnershipSuccessOpen(false)}
        onRequestClose={() => setIsModalDeactivatePartnershipSuccessOpen(false)}
      />

      <SimpleModal
        isOpen={isModalActivatePartnershipOpen}
        title="Ativar parceria"
        message="Deseja continuar com esta operação?"
        icon={warningImg}
        showCancel
        cancelButtonText="Voltar"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => {
          handleTogglePartnership();
          setIsModalActivatePartnershipOpen(false);
          setIsModalActivatePartnershipSuccessOpen(true);
        }}
        onRequestClose={() => setIsModalActivatePartnershipOpen(false)}
      />

      <SimpleModal
        isOpen={isModalActivatePartnershipSuccessOpen}
        title="Sucesso"
        message="Parceria foi ativada com sucesso."
        icon={successImg}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => setIsModalActivatePartnershipSuccessOpen(false)}
        onRequestClose={() => setIsModalActivatePartnershipSuccessOpen(false)}
      />

      <HandleConfigModal />
    </>
  );
}
